export const translations = {
    locale: "en",
    get: (id, parameters) => {
        if (!id)
            return "";
        const object = translations[translations.locale] ?? translations.en;
        let translation = object[id] ?? translations.en[id] ?? "";
        if (!translation) {
            console.error("Missing translation for '" + id + "'");
            translation = id;
        }
        if (parameters) {
            for (const param in parameters)
                translation = translation.replaceAll("{" + param + "}", parameters[param] + "");
        }
        return translation;
    },
    en: {
        UnlockPremiumFeatures: "Unlock Premium to enable more features",
        UpgradePremium: "Upgrade Premium",
        UpgradePlatinum: "Upgrade Platinum",
        Cancel: "Cancel",
        ThanksPurchase: "Thank you for your purchase",
        PremiumNowAvailable: "You can now enjoy all the premium features",
        ManageLicences: "Manage licences",
        ManageMyLicence: "Manage my licence",
        Close: "Close",
        RequestAccess: "Request Access",
        UpgradeTeamMessage: "Upgrade to Premium for your own team. You will be able to manage the members who will have access to the Premium version.",
        UpgradePlatinumTeamMessage: "Upgrade to Platinum for your own team. You will be able to manage the members who will have access to the Platinum version.",
        TeamHasLicences: "Your company already upgraded to Premium. Leave a message to the administrator to request access.",
        NoPremiumAccess: "You do not have Premium access",
        TeamHasPlatinumLicences: "Your company already upgraded to Platinum. Leave a message to the administrator to request access.",
        NoPlatinumAccess: "You do not have Platinum access",
        RequestAccessMessage: "Leave a message to the administrator to request access. It will be notified and will grant access soon.",
        Send: "Send",
        AdministratorNotified: "The administrator has been notified. You will get an email when it is done.",
        DefaultRequestMessage: "Hey, I am {userName} and I would like to have Premium access on {appTitle}. Thank you.",
        RequestAccessInApprobation: "An access request is being approved. If you are in a hurry, activate Premium now",
        YouAreAdmin: "You are administrator",
        YouCanManageLicences: "You have the possibility to manage licenses for this application, if you wish, click on the button below to assign you a Premium license.",
        YouCanManageLicencesToPlatinum: "You have the possibility to manage licenses for this application, if you wish, click on the button below to assign you a Platinum license.",
        SendMessage: "Send message",
        Call: "Call",
        System: "System",
        Unknown: "Unknown",
        TokenExpired: "Token expired",
        PleaseRefreshTab: "Please refresh tab",
        AnErrorOccured: "An error occured",
        Consent: "Consent",
        NeedToConsent: "Need to consent",
        ClickButtonToContinue: "Click button to continue",
        SearchATeam: "Search a team",
        JoinTeam: "Join team",
        Teams: "Teams",
        EditTextConfirm: "Save",
        EditTextEdit: "Edit",
        EditTextRemove: "Remove",
        EditTextLink: "Link",
        "Permissions:Title": "Permissions",
        "Permissions:Search": "Search for members",
        "Permissions:TableName": "Name",
        "Permissions:TableRole": "Role",
        "Calendar:Permissions:Tableadd": "Add",
        "Calendar:Permissions:Tableupdate": "Modify",
        "Calendar:Permissions:Tabledelete": "Delete",
        "Calendar:Permissions:TableallowShareSynchro": "Calendar Sharing",
        "Calendar:Permissions:TableallowEditMeetingScheduler": "Meeting Scheduler",
        "SharedCalendar:Permissions:Tableadd": "Add",
        "SharedCalendar:Permissions:Tableupdate": "Modify",
        "SharedCalendar:Permissions:Tabledelete": "Delete",
        "SharedCalendar:Permissions:TableallowShareSynchro": "Calendar Sharing",
        "SharedCalendar:Permissions:TableallowEditMeetingScheduler": "Meeting Scheduler",
        "Booking:Permissions:Tableadd": "Add",
        "Booking:Permissions:Tableupdate": "Modify",
        "Booking:Permissions:Tabledelete": "Delete",
        "Parking:Permissions:Tableadd": "Add",
        "Parking:Permissions:Tableupdate": "Modify",
        "Parking:Permissions:Tabledelete": "Delete",
        "Contact:Permissions:Tableadd": "Add",
        "Contact:Permissions:Tableupdate": "Modify",
        "Contact:Permissions:Tabledelete": "Delete",
        "Keepass:Permissions:Tableadd": "Add",
        "Keepass:Permissions:Tableupdate": "Modify",
        "Keepass:Permissions:Tabledelete": "Delete / Retrieve",
        "Permissions:CancelButton": "Cancel",
        "Permissions:SaveButton": "Save",
        "Permissions:AddMemberButton": "Add member",
        "Permissions:GeneralSetting": "Default permissions",
        "Calendar:Permissions:addInfo": "User will be able to add events",
        "Calendar:Permissions:updateInfo": "User will be able to modify events",
        "Calendar:Permissions:deleteInfo": "User will be able to delete events",
        "Calendar:Permissions:allowEditMeetingSchedulerInfo": "The user will be able to manage meeting scheduler settings",
        "Calendar:Permissions:allowShareSynchroInfo": "User will be able to share his synchronized calendars with everyone",
        "SharedCalendar:Permissions:addInfo": "User will be able to add events",
        "SharedCalendar:Permissions:updateInfo": "User will be able to modify events",
        "SharedCalendar:Permissions:deleteInfo": "User will be able to delete events",
        "SharedCalendar:Permissions:allowEditMeetingSchedulerInfo": "The user will be able to manage meeting scheduler settings",
        "SharedCalendar:Permissions:allowShareSynchroInfo": "User will be able to share his synchronized calendars with everyone",
        "Booking:Permissions:addInfo": "User will be able to add booking",
        "Booking:Permissions:updateInfo": "User will be able to modify booking",
        "Booking:Permissions:deleteInfo": "User will be able to delete booking",
        "Parking:Permissions:addInfo": "User will be able to add booking",
        "Parking:Permissions:updateInfo": "User will be able to modify booking",
        "Parking:Permissions:deleteInfo": "User will be able to delete booking",
        "Contact:Permissions:addInfo": "The user will be able to add contacts and groups",
        "Contact:Permissions:updateInfo": "The user will be able to modify contacts and groups",
        "Contact:Permissions:deleteInfo": "The user will be able to delete contacts and groups",
        "Keepass:Permissions:addInfo": "User will be able to add password",
        "Keepass:Permissions:updateInfo": "User will be able to modify password",
        "Keepass:Permissions:deleteInfo": "User will be able to delete and retrieve password",
        "Permissions:Owner": "Owner",
        "Permissions:Admin": "Administrator",
        "Permissions:Member": "Member",
        "Permissions:MemberIsNotPlatinum": "Customization exclusive to Platinum subscribers",
        "Permissions:EveryoneDropdown": "Everybody in this channel can add/edit/delete {taskName}",
        "Permissions:CreatorDropdown": "Only the owner(s) of this {appTitle} can add/edit/delete {taskName}",
        "Permissions:CustomDropdown": "Customize permissions",
        "Permissions:Platinum": "Platinum",
        "Permissions:Premium": "<a href='https://admin.teams-pro.com/users' target='_blank'>Premium</a>",
        "Permissions:Free": "<a href='https://admin.teams-pro.com/users' target='_blank'>Free</a>",
        "Permissions:AssignLicense": "Click to assign a Platinum license 🚀",
        "Permissions:Subscription": "Subscription",
        "Booking:taskName": "bookings",
        "Parking:taskName": "bookings",
        "Calendar:taskName": "events",
        "SharedCalendar:taskName": "events",
        "Keepass:taskName": "passwords",
        "Contact:taskName": "contacts and groups",
        "Gantt:taskName": "tasks",
        AddMembers: "Add members",
        AddMembersTagLine: "Start typing the email address of the person you wish to add",
        EmailAddress: "Enter email address",
        SaveAlert: "Your modifications have been successfully saved!",
        CancelTagLine: "If you proceed now, any changes you've made will be discarded. Are you sure you want to continue?",
        Continue: "Continue",
        Return: "Return",
        "Permissions:FreeRedirect": "You're using a free version of {appTitle}.",
        "Permissions:PremiumRedirect": "You're using a premium version of {appTitle}.",
        "Sync:PremiumRedirect": "You're using a premium version of {appTitle}. Click <a href='https://admin.teams-pro.com/services' target='_blank'>here 🚀</a> to upgrade to Platinum and manage synchronizations!",
        "Sync:FreeRedirect": "You're using a free version of {appTitle}. Click <a href='https://admin.teams-pro.com/services' target='_blank'>here 🚀</a> to upgrade to Platinum and manage synchronizations!",
        NotAllowed: "You are not allowed to change the permissions.",
        NotAllowedNotif: "You are not allowed to change this settings.",
        CancelModifications: "Cancel modifications",
        "Sync:Title": "Synchronize calendars",
        "Sync:Manage": "Manage",
        "Sync:Synchronize": "Synchronize",
        "Sync:Add": "Add a calendar",
        "Sync:SyncCalendarTitle": "Synchronize calendars to my ",
        "Sync:ShareTitle": "Share my ",
        "Sync:CalendarName": "Name of the calendar",
        "Sync:ICSLink": ".ICS link of the calendar",
        "Sync:PublicAddress": "Public address iCal format",
        "Sync:TableName": "Name",
        "Sync:TableColor": "Color",
        "Sync:TableShow": "Share",
        "Sync:TableState": "State",
        "Sync:SynchronizeLine": "To handle all your calendars in one place, synchronize your calendar.",
        "Sync:SyncCalendarTooltip": " You can synchronize maximum 3 calendars. Your calendar will be synchronized every 15 minutes.",
        "Sync:SyncTitle": "Add external calendar",
        AddAlert: "The member(s) have been successfully added",
        "Sync:ICSInput": "Type an url",
        "Sync:NameInput": "Type a name",
        "Sync:TableAction": "Action",
        "Sync:ManageTooltip": "You do not have a single synchronized calendar",
        AddressTitle: "Address",
        AddAddress: "Type here to search an address",
        Participants: "Participants",
        NoParticipants: "No result found",
        "Sync:TabTooltip": "You have to save/cancel your changes",
        "Sync:DeleteCalendar": "Delete calendar",
        "Sync:DeleteTagLine": "If you proceed now, your calendar will be deleted from your view. To see it again, you will have to synchronize it again.",
        "Sync:NotAllowToShare": "The owner(s) of the Calendar did not allow you to share your calendars with others",
        "Sync:ConnectionIssue": "Problème de connexion au calendrier détecté. Vérifiez l'existence du calendrier et l'état de la connexion pour une synchronisation fluide.",
        "Permissions:CreatorRole": "Owner of this calendar",
        Synchronized: "Synchronized",
        Unsynchronized: "Unsynchronized",
        "Sync:UnsynchronizedTooltip": "Calendar connection issue detected. Verify calendar existence and connection status for seamless synchronization",
        "Sync:ShareInfo": "Share this calendar with members on ",
        AddParticipantsButton: "Add participants",
        "Sync:getErrorMessage": "An error with your ICS link occurred on the calendar : ",
        "Permissions:ManageRoleTitle": "Role Change Confirmation",
        "Permissions:ManageRoleTagLine": "You are about to change the role of a member in your tab. By proceeding, you will grant rights that you won't be able to revoke from a member. Are you sure you want to continue?",
        NotifDialogHeader: "Teams notification settings",
        RedirectToAppSource: "<p style='text-align: center;'>We're bringing you to the Microsoft Marketplace. If you're not redirected, <a target='_blank' href='{link}'>click here</a>.</p>",
        WhatsNewsOn: "What's new?",
        NewsSoon: "News will come soon!",
        NeedConsentTitle: "Need consent",
        NeedConsent: "To perform this action we will need consent. If you confirm you will be redirect to the consent page.",
        NoAddress: "No result found on Google Maps",
        YourInfo: "Your info",
        ChooseTime: "Choose a date and time",
        IC_SuperAdminTitle: "Warning",
        IC_SuperAdminMessage: "Your payment information expires soon, please update it to avoid service disruption.",
        IC_SuperAdminCTA: "Go to Admin Center",
        IC_AdminTitle: "Warning",
        IC_AdminMessage: "Your associated licence's payment method is about to expire.",
        IC_AdminCTA: "Click here to inform your Admin",
        IC_NotifTitle: "Attention needed",
        IC_NotifMessage: "Please access {appName} for important updates",
        IC_SuccesNotifTitle: "Success",
        IC_SuccesNotifMessage: "Notification has been successfully sent to your Super Admin.",
        PreventUnlockPremiumFeaturesOnMobile: "Visit the Admin Center to upgrade Contacts Pro.",
        GoToAdminCenter: "Go to Admin Center",
        PremiumMessage1: "You're using a premium version of {appTitle}.",
        ClickHere: "Click here 🚀",
        PremiumMessage2: "to upgrade to Platinum and manage permissions more effectively!",
        DiscardMessageTitle: "Unsaved changes",
        DiscardChanges: "Discard",
        ContinueEditing: "Continue editing",
        DiscardMessage: "Do you want to discard edits or continue editing?",
        VisitLink: "Visit the link",
        SelectCustomize: "To customize permissions, select 'Customize permissions' above",
        SaveBeforeSearch: "Save your modifications before searching",
        AccessMaster: "This user will have access to master password",
        "SuperAdmin:NoResult": "No search results were found.",
        FeaturesNowAvailable: "Enjoy all your new features now!",
        KnowAboutFeatures: "To learn more about the features available to you, click <a target='_blank' href='{link}'>here</a>!",
        Go: "Let's go!",
        NeedToBuyLicences: "You need to buy licences",
        HaveLicencesButNotEnough: "You already have some licences but they are all assigned. You have to buy more licences to assign one to yourself.",
        BuyMoreLicence: "Buy more licences",
        GeneralPermissionsSettings: "General permissions settings",
        CustomizedPermissionsSettings: "Customized permissions settings",
        About: "About",
        Version: "Version",
        UniqueID: "Unique identifier",
        Copied: "Copied!",
        Copy: "Copy",
        CreatedBy: "Created by",
        SuggestFeature: "Suggest a Feature",
        HelpCenter: "Help Center",
        "MoreApps": "More Apps",
    },
    fr: {
        SendMessage: "Envoyer un message",
        Call: "Appeler",
        System: "Système",
        Unknown: "Inconnu",
        TokenExpired: "Token expiré",
        PleaseRefreshTab: "Veuillez rafraîchir l'onglet",
        AnErrorOccured: "Une erreur est survenue",
        Consent: "Consentir",
        NeedToConsent: "Vous devez donner votre accord",
        ClickButtonToContinue: "Cliquez sur le bouton pour continuer",
        SearchATeam: "Rechercher une équipe",
        JoinTeam: "Rejoindre l'équipe",
        Teams: "Équipes",
        EditTextConfirm: "Sauvegarder",
        EditTextEdit: "Edit",
        EditTextRemove: "Supprimer",
        EditTextLink: "Link",
        UnlockPremiumFeatures: "Accédez au Premium pour obtenir plus de fonctionnalités",
        UpgradePremium: "Activer Premium",
        UpgradePlatinum: "Activer Platinum",
        Cancel: "Annuler",
        ThanksPurchase: "Merci pour votre achat",
        PremiumNowAvailable: "Vous pouvez dès à présent profiter de toutes les fonctionnalités premium",
        ManageLicences: "Gérer les licences",
        ManageMyLicence: "Gérer ma licence",
        Close: "Fermer",
        RequestAccess: "Demander un accès",
        UpgradeTeamMessage: "Activez la version Premium pour votre équipe. Vous aurez la possibilité de gérer les membres qui auront accès à la version Premium.",
        UpgradePlatinumTeamMessage: "Activez la version Platinum pour votre équipe. Vous aurez la possibilité de gérer les membres qui auront accès à la version Platinum.",
        TeamHasLicences: "Votre entreprise a déjà accès à la version Premium. Envoyez un message à l'administrateur pour demander un accès.",
        NoPremiumAccess: "Vous n'avez pas d'accès Premium",
        TeamHasPlatinumLicences: "Votre entreprise a déjà accès à la version Platinum. Envoyez un message à l'administrateur pour demander un accès.",
        NoPlatinumAccess: "Vous n'avez pas d'accès Platinum",
        RequestAccessMessage: "Laissez un message à l'administrateur pour votre demande d'accès. Il sera notifié et vous donnera un accès sous peu.",
        Send: "Envoyer",
        AdministratorNotified: "L'administrateur a été notifié. Vous recevrez un email lorsque votre demande aura été validé.",
        DefaultRequestMessage: "Bonjour, je suis {userName} et je souhaiterai avoir accès à la version Premium de {appTitle}. Merci.",
        RequestAccessInApprobation: "Une demande d’accès est en cours d’approbation. Si vous êtes pressé, activez le Premium maintenant",
        YouAreAdmin: "Vous êtes administrateur",
        YouCanManageLicences: "Vous avez la possibilité de gérer les licences pour cette application, si vous le souhaitez, cliquez sur le bouton ci-dessous pour vous assigner une licence Premium.",
        YouCanManageLicencesToPlatinum: "Vous avez la possibilité de gérer les licences pour cette application, si vous le souhaitez, cliquez sur le bouton ci-dessous pour vous assigner une licence Platinum.",
        "Permissions:Title": "Permissions",
        "Permissions:Search": "Rechercher des membres",
        "Permissions:TableName": "Nom",
        "Permissions:TableRole": "Rôle",
        "Calendar:Permissions:Tableadd": "Ajouter",
        "Calendar:Permissions:Tableupdate": "Modifier",
        "Calendar:Permissions:Tabledelete": "Supprimer",
        "Calendar:Permissions:TableallowShareSynchro": "Partage de calendrier",
        "Calendar:Permissions:TableallowEditMeetingScheduler": "Plateforme de rendez-vous",
        "SharedCalendar:Permissions:Tableadd": "Ajouter",
        "SharedCalendar:Permissions:Tableupdate": "Modifier",
        "SharedCalendar:Permissions:Tabledelete": "Supprimer",
        "SharedCalendar:Permissions:TableallowShareSynchro": "Partage de calendrier",
        "SharedCalendar:Permissions:TableallowEditMeetingScheduler": "Plateforme de rendez-vous",
        "Booking:Permissions:Tableadd": "Ajouter",
        "Booking:Permissions:Tableupdate": "Modifier",
        "Booking:Permissions:Tabledelete": "Supprimer",
        "Parking:Permissions:Tableadd": "Ajouter",
        "Parking:Permissions:Tableupdate": "Modifier",
        "Parking:Permissions:Tabledelete": "Supprimer",
        "Contact:Permissions:Tableadd": "Ajouter",
        "Contact:Permissions:Tableupdate": "Modifier",
        "Contact:Permissions:Tabledelete": "Supprimer",
        "Keepass:Permissions:Tableadd": "Ajouter",
        "Keepass:Permissions:Tableupdate": "Modifier",
        "Keepass:Permissions:Tabledelete": "Supprimer / Récupérer",
        "Permissions:CancelButton": "Annuler",
        "Permissions:SaveButton": "Sauvegarder",
        "Permissions:AddMemberButton": "Ajouter un membre",
        "Permissions:GeneralSetting": "Paramètre par default",
        "Calendar:Permissions:addInfo": "L'utilisateur pourra ajouter des évènements",
        "Calendar:Permissions:updateInfo": "L'utilisateur pourra modifer des évènements",
        "Calendar:Permissions:deleteInfo": "L'utilisateur pourra supprimer des évènements",
        "Calendar:Permissions:allowEditMeetingSchedulerInfo": "L'utilisateur pourra gérer les paramètres de la plateforme de rendez-vous",
        "Calendar:Permissions:allowShareSynchroInfo": "L'utilisateur pourra partager ses calendriers synchronisés avec tout le monde",
        "SharedCalendar:Permissions:addInfo": "L'utilisateur pourra ajouter des évènements",
        "SharedCalendar:Permissions:updateInfo": "L'utilisateur pourra modifer des évènements",
        "SharedCalendar:Permissions:deleteInfo": "L'utilisateur pourra supprimer des évènements",
        "SharedCalendar:Permissions:allowEditMeetingSchedulerInfo": "L'utilisateur pourra gérer les paramètres de la plateforme de rendez-vous",
        "SharedCalendar:Permissions:allowShareSynchroInfo": "L'utilisateur pourra partager ses calendriers synchronisés avec tout le monde",
        "Booking:Permissions:addInfo": "L'utilisateur pourra ajouter des réservations",
        "Booking:Permissions:updateInfo": "L'utilisateur pourra modifer des réservations",
        "Booking:Permissions:deleteInfo": "L'utilisateur pourra supprimer des réservations",
        "Parking:Permissions:addInfo": "L'utilisateur pourra ajouter des réservations",
        "Parking:Permissions:updateInfo": "L'utilisateur pourra modifer des réservations",
        "Parking:Permissions:deleteInfo": "L'utilisateur pourra supprimer des réservations",
        "Contact:Permissions:addInfo": "L'utilisateur pourra ajouter des contacts et des groupes",
        "Contact:Permissions:updateInfo": "L'utilisateur pourra modifier des contacts et des groupes",
        "Contact:Permissions:deleteInfo": "L'utilisateur pourra supprimer des contacts et des groupes",
        "Keepass:Permissions:addInfo": "L'utilisateur pourra ajouter des mots de passe",
        "Keepass:Permissions:updateInfo": "L'utilisateur pourra modifier des mots de passe",
        "Keepass:Permissions:deleteInfo": "L'utilisateur pourra supprimer et restaurer des mots de passe",
        "Permissions:Owner": "Propriétaire",
        "Permissions:Admin": "Administrateur",
        "Permissions:Member": "Membre",
        "Permissions:MemberIsNotPlatinum": "Personnalisation réservée aux abonnés Platinum",
        "Permissions:EveryoneDropdown": "Tout le monde dans ce canal peut ajouter/éditer/supprimer {taskName}",
        "Permissions:CreatorDropdown": "Seul(s) le(s) propriétaire(s) de {appTitle} peut ajouter/éditer/supprimer {taskName}",
        "Permissions:CustomDropdown": "Personnaliser les autorisations",
        "Permissions:Platinum": "Platinum",
        "Permissions:Premium": "<a href='https://admin.teams-pro.com/users' target='_blank'>Premium</a>",
        "Permissions:Free": "<a href='https://admin.teams-pro.com/users' target='_blank'>Gratuite</a>",
        "Permissions:AssignLicense": "Cliquez pour assigner une licence Platinum 🚀",
        "Permissions:Subscription": "Souscription",
        AddMembers: "Ajouter des membres",
        AddMembersTagLine: "Entrer l'adresse mail de la personne que vous souhaitez ajouter",
        EmailAddress: "Entrer une adresse mail",
        SaveAlert: "Vos modifications ont été sauvegardé avec succès !",
        CancelTagLine: "Si vous continuez maintenant, toutes les modifications que vous avez apportées seront perdues. Êtes-vous sûr de vouloir continuer ?",
        Continue: "Continuer",
        Return: "Retour",
        "Permissions:FreeRedirect": "Vous utilisez une version gratuite de {appTitle}.",
        "Permissions:PremiumRedirect": "Vous utilisez une version premium de {appTitle}.",
        "Booking:taskName": "une réservation",
        "Parking:taskName": "une réservation",
        "Calendar:taskName": "un évenement",
        "SharedCalendar:taskName": "un évenement",
        "Contact:taskName": "un contact et un groupe",
        "Gantt:taskName": "une tâche",
        "Keepass:taskName": "un mot de passe",
        "Sync:PremiumRedirect": "Vous utilisez une version premium de Calendar Pro. Cliquez <a href='https://admin.teams-pro.com/services' target='_blank'>ici 🚀</a> pour passer à la version Platinum et gérer les synchronisations !",
        "Sync:FreeRedirect": "Vous utilisez une version gratuite de Calendar Pro. Cliquez <a href='https://admin.teams-pro.com/services' target='_blank'>ici 🚀</a> pour passer à la version Platinum et gérer les synchronisations !",
        NotAllowed: "Vous n'êtes pas autorisé à modifier les permissions.",
        NotAllowedNotif: "Vous n'êtes pas autorisé à modifier ces paramètres.",
        CancelModifications: "Annuler les modifications",
        "Sync:Title": "Synchroniser des calendriers",
        "Sync:Manage": "Gérer",
        "Sync:Synchronize": "Synchroniser",
        "Sync:Add": "Ajouter un calendrier",
        "Sync:SyncCalendarTitle": "Synchroniser des calendriers à mon ",
        "Sync:ShareTitle": "Partager mon ",
        "Sync:CalendarName": "Nom du calendrier",
        "Sync:ICSLink": "Lien .ICS du calendrier",
        "Sync:PublicAddress": "Adresse publique au format iCal",
        "Sync:TableName": "Nom",
        "Sync:TableColor": "Couleur",
        "Sync:TableShow": "Partager",
        "Sync:TableState": "État",
        "Sync:SynchronizeLine": "Pour gérer tous vos calendriers en un seul endroit, synchronisez votre calendrier.",
        "Sync:SyncCalendarTooltip": "Vous pouvez synchroniser 3 calendriers maximum. Votre calendrier sera synchronisé toutes les 15 minutes.",
        "Sync:SyncTitle": "Ajoutez vos calendriers externes",
        AddAlert: "Le(s) membre(s) a/ont bien été ajouté",
        "Sync:ICSInput": "Entrer une url",
        "Sync:NameInput": "Entrer un nom",
        "Sync:TableAction": "Action",
        "Sync:ManageTooltip": "Vous n'avez pas de calendrier synchronisé",
        AddressTitle: "Adresse",
        AddAddress: "Saisir une adresse",
        Participants: "Participants",
        NoParticipants: "Pas de résultat",
        "Sync:TabTooltip": "Vous devez enregistrer/annuler vos changements",
        "Sync:DeleteCalendar": "Supprimer le calendrier",
        "Sync:DeleteTagLine": "Si vous continuez maintenant, votre calendrier sera supprimé de votre vue. Pour le voir à nouveau, vous devrez à nouveau le synchroniser.",
        "Sync:NotAllowToShare": "Le(s) propriétaire(s) du calendrier ne vous a pas autorisé à partager vos calendriers avec les autres utilisateurs",
        "Sync:ConnectionIssue": "Calendar connection issue detected. Verify calendar existence and connection status for seamless synchronization",
        "Permissions:CreatorRole": "Propriétaire de ce calendrier",
        Synchronized: "Synchronisé",
        Unsynchronized: "Désynchronisé",
        "Sync:UnsynchronizedTooltip": "Problème de connexion au calendrier détecté. Vérifiez l'existence du calendrier et l'état de la connexion pour une synchronisation sans faille.",
        "Sync:ShareInfo": "Partager ce calendrier avec les membres de ",
        AddParticipantsButton: "Ajouter des participants",
        "Sync:getErrorMessage": "Une erreur avec votre lien ICS est survenue sur le calendrier : ",
        "Permissions:ManageRoleTitle": "Confirmation du changement de rôle",
        "Permissions:ManageRoleTagLine": "Vous êtes sur le point de modifier le rôle d'un membre. En faisant celà vous allez octroyez des droits que vous ne pourrez pas retirer à un membre. Êtes-vous sûr de vouloir continuer ?",
        NotifDialogHeader: "Paramétrage des notifications Teams",
        RedirectToAppSource: "<p style='text-align: center;'>Vous allez être redirigés vers Microsoft Marketplace, si vous n'êtes pas redirigé, <a target='_blank' href='{link}'>cliquez ici</a>.</a>",
        WhatsNewsOn: "Nouveautés",
        NewsSoon: "Des nouveautés arrivent bientôt!",
        NeedConsentTitle: "Besoin d'autorisation",
        NeedConsent: "Pour utiliser cette fonctionalité, des droits supplémentaire sont recquis. Si vous acceptez, vous serez redirigé sur la page d'autorisation.",
        NoAddress: "Pas de résultats trouvés sur Google Maps",
        YourInfo: "Vos informations",
        ChooseTime: "Choisir la date et l'heure",
        IC_SuperAdminTitle: "Attention",
        IC_SuperAdminMessage: "Vos informations de paiement expirent bientôt, veuillez les mettre à jour pour éviter toute interruption de service.",
        IC_SuperAdminCTA: "Aller sur Admin Center",
        IC_AdminTitle: "Attention",
        IC_AdminMessage: "La méthode de paiement de votre licence associée est sur le point d'expirer.",
        IC_AdminCTA: "Cliquez ici pour informer votre Admin",
        IC_NotifTitle: "Attention requise",
        IC_NotifMessage: "Veuillez accéder à {appName} pour des mises à jour importantes",
        IC_SuccesNotifTitle: "Succès",
        IC_SuccesNotifMessage: "La notification a été envoyée avec succès à votre Super Admin.",
        PreventUnlockPremiumFeaturesOnMobile: "Visitez le Centre d'administration pour passer à Contacts Pro.",
        GoToAdminCenter: "Accéder au Centre d'administration",
        PremiumMessage1: "Vous utilisez une version premium de {appTitle}.",
        ClickHere: "Cliquez ici 🚀",
        PremiumMessage2: "pour passer à Platinum et gérer les autorisations de manière plus efficace !",
        DiscardMessageTitle: "Modifications non enregistrées",
        DiscardChanges: "Ignorer",
        ContinueEditing: "Continuer l'édition",
        DiscardMessage: "Voulez-vous ignorer les modifications apportées ou continuer la modification ?",
        VisitLink: "Visiter le lien",
        SelectCustomize: "Pour personnaliser les autorisations, sélectionnez 'Personnaliser les autorisations' ci-dessus",
        SaveBeforeSearch: "Enregistrez vos modifications avant de rechercher",
        AccessMaster: "Cet utilisateur aura accès au mot de passe maître",
        "SuperAdmin:NoResult": "Aucun résultat de recherche n'a été trouvé.",
        FeaturesNowAvailable: "Profitez de toutes vos nouvelles fonctionnalités dès maintenant!",
        KnowAboutFeatures: "Pour en savoir plus sur les fonctionnalités disponibles pour vous, cliquez <a target='_blank' href='{link}'>ici</a>!",
        Go: "C'est parti!",
        NeedToBuyLicences: "Vous devez acheter des licences",
        HaveLicencesButNotEnough: "Vous avez déjà des licences mais elles sont toutes attribuées. Vous devez acheter plus de licences pour vous en attribuer une.",
        BuyMoreLicence: "Acheter plus de licences",
        GeneralPermissionsSettings: "Paramètres de permissions générales",
        CustomizedPermissionsSettings: "Paramètres de permissions personnalisés",
        About: "À propos",
        Version: "Version",
        UniqueID: "Identifiant unique",
        Copied: "Copié!",
        Copy: "Copier",
        CreatedBy: "Créé par",
        SuggestFeature: "Suggérer une fonctionnalité",
        HelpCenter: "Centre d'aide",
        "MoreApps": "Plus d'applications",
    },
    de: {
        UnlockPremiumFeatures: "Schalte Premium frei, um mehr Funktionen zu aktivieren",
        UpgradePremium: "Premium aktualisieren",
        UpgradePlatinum: "Platinum aktualisieren",
        Cancel: "Abbrechen",
        ThanksPurchase: "Vielen Dank für Ihren Einkauf",
        PremiumNowAvailable: "Sie können jetzt alle Premium-Funktionen nutzen",
        ManageLicences: "Lizenzen verwalten",
        ManageMyLicence: "Meine Lizenz verwalten",
        Close: "Schließen",
        RequestAccess: "Zugriff anfordern",
        UpgradeTeamMessage: "Aktualisieren Sie auf Premium für Ihr eigenes Team. Sie können die Mitglieder verwalten, die Zugriff auf die Premium-Version haben werden.",
        UpgradePlatinumTeamMessage: "Aktualisieren Sie auf Platinum für Ihr eigenes Team. Sie können die Mitglieder verwalten, die Zugriff auf die Platinum-Version haben werden.",
        TeamHasLicences: "Ihr Unternehmen hat bereits auf Premium aktualisiert. Hinterlassen Sie eine Nachricht beim Administrator, um Zugriff anzufordern.",
        NoPremiumAccess: "Sie haben keinen Premium-Zugriff",
        TeamHasPlatinumLicences: "Ihr Unternehmen hat bereits auf Platinum aktualisiert. Hinterlassen Sie eine Nachricht beim Administrator, um Zugriff anzufordern.",
        NoPlatinumAccess: "Sie haben keinen Platinum-Zugriff",
        RequestAccessMessage: "Hinterlassen Sie eine Nachricht beim Administrator, um Zugriff anzufordern. Dieser wird benachrichtigt und gewährt bald Zugriff.",
        Send: "Senden",
        AdministratorNotified: "Der Administrator wurde benachrichtigt. Sie erhalten eine E-Mail, wenn dies erledigt ist.",
        DefaultRequestMessage: "Hallo, ich bin {userName} und möchte auf {appTitle} Premium-Zugriff haben. Danke.",
        RequestAccessInApprobation: "Eine Zugriffsanforderung wird genehmigt. Wenn es eilig ist, aktivieren Sie jetzt Premium",
        YouAreAdmin: "Sie sind Administrator",
        YouCanManageLicences: "Sie haben die Möglichkeit, Lizenzen für diese Anwendung zu verwalten. Klicken Sie auf die Schaltfläche unten, um sich eine Premium-Lizenz zuzuweisen.",
        YouCanManageLicencesToPlatinum: "Sie haben die Möglichkeit, Lizenzen für diese Anwendung zu verwalten. Wenn Sie möchten, klicken Sie auf die Schaltfläche unten, um sich eine Platinum-Lizenz zuzuweisen.",
        SendMessage: "Nachricht senden",
        Call: "Anruf",
        System: "System",
        Unknown: "Unbekannt",
        TokenExpired: "Token abgelaufen",
        PleaseRefreshTab: "Bitte Tab neu laden",
        AnErrorOccured: "Ein Fehler ist aufgetreten",
        Consent: "Einwilligung",
        NeedToConsent: "Einwilligung erforderlich",
        ClickButtonToContinue: "Klicken Sie auf die Schaltfläche, um fortzufahren",
        SearchATeam: "Ein Team suchen",
        JoinTeam: "Team beitreten",
        Teams: "Teams",
        EditTextConfirm: "Speichern",
        EditTextEdit: "Bearbeiten",
        EditTextRemove: "Entfernen",
        EditTextLink: "Verknüpfen",
        "Permissions:Title": "Berechtigungen",
        "Permissions:Search": "Nach Mitgliedern suchen",
        "Permissions:TableName": "Name",
        "Permissions:TableRole": "Rolle",
        "Calendar:Permissions:Tableadd": "Hinzufügen",
        "Calendar:Permissions:Tableupdate": "Bearbeiten",
        "Calendar:Permissions:Tabledelete": "Löschen",
        "Calendar:Permissions:TableallowShareSynchro": "Kalenderfreigabe",
        "Calendar:Permissions:TableallowEditMeetingScheduler": "Besprechungsplaner",
        "SharedCalendar:Permissions:Tableadd": "Hinzufügen",
        "SharedCalendar:Permissions:Tableupdate": "Bearbeiten",
        "SharedCalendar:Permissions:Tabledelete": "Löschen",
        "SharedCalendar:Permissions:TableallowShareSynchro": "Kalenderfreigabe",
        "SharedCalendar:Permissions:TableallowEditMeetingScheduler": "Besprechungsplaner",
        "Booking:Permissions:Tableadd": "Hinzufügen",
        "Booking:Permissions:Tableupdate": "Bearbeiten",
        "Booking:Permissions:Tabledelete": "Löschen",
        "Parking:Permissions:Tableadd": "Hinzufügen",
        "Parking:Permissions:Tableupdate": "Bearbeiten",
        "Parking:Permissions:Tabledelete": "Löschen",
        "Contact:Permissions:Tableadd": "Hinzufügen",
        "Contact:Permissions:Tableupdate": "Bearbeiten",
        "Contact:Permissions:Tabledelete": "Löschen",
        "Keepass:Permissions:Tableadd": "Hinzufügen",
        "Keepass:Permissions:Tableupdate": "Bearbeiten",
        "Keepass:Permissions:Tabledelete": "Löschen / Wiederherstellen",
        "Permissions:CancelButton": "Abbrechen",
        "Permissions:SaveButton": "Speichern",
        "Permissions:AddMemberButton": "Mitglied hinzufügen",
        "Permissions:GeneralSetting": "Standardberechtigungen",
        "Calendar:Permissions:addInfo": "Benutzer kann Ereignisse hinzufügen",
        "Calendar:Permissions:updateInfo": "Benutzer kann Ereignisse bearbeiten",
        "Calendar:Permissions:deleteInfo": "Benutzer kann Ereignisse löschen",
        "Calendar:Permissions:allowEditMeetingSchedulerInfo": "Der Benutzer kann die Einstellungen des Besprechungsplaners verwalten",
        "Calendar:Permissions:allowShareSynchroInfo": "Benutzer kann seine synchronisierten Kalender mit jedem teilen",
        "SharedCalendar:Permissions:addInfo": "Benutzer kann Ereignisse hinzufügen",
        "SharedCalendar:Permissions:updateInfo": "Benutzer kann Ereignisse bearbeiten",
        "SharedCalendar:Permissions:deleteInfo": "Benutzer kann Ereignisse löschen",
        "SharedCalendar:Permissions:allowEditMeetingSchedulerInfo": "Der Benutzer kann die Einstellungen des Besprechungsplaners verwalten",
        "SharedCalendar:Permissions:allowShareSynchroInfo": "Benutzer kann seine synchronisierten Kalender mit jedem teilen",
        "Booking:Permissions:addInfo": "Benutzer kann Buchung hinzufügen",
        "Booking:Permissions:updateInfo": "Benutzer kann Buchung bearbeiten",
        "Booking:Permissions:deleteInfo": "Benutzer kann Buchung löschen",
        "Parking:Permissions:addInfo": "Benutzer kann Buchung hinzufügen",
        "Parking:Permissions:updateInfo": "Benutzer kann Buchung bearbeiten",
        "Parking:Permissions:deleteInfo": "Benutzer kann Buchung löschen",
        "Contact:Permissions:addInfo": "Der Benutzer wird Kontakte und Gruppen hinzufügen können",
        "Contact:Permissions:updateInfo": "Der Benutzer wird Kontakte und Gruppen bearbeiten können",
        "Contact:Permissions:deleteInfo": "Der Benutzer wird Kontakte und Gruppen löschen können",
        "Keepass:Permissions:addInfo": "Benutzer kann Passwörter hinzufügen",
        "Keepass:Permissions:updateInfo": "Benutzer kann Passwörter bearbeiten",
        "Keepass:Permissions:deleteInfo": "Benutzer kann Passwörter löschen und wiederherstellen",
        "Permissions:Owner": "Besitzer",
        "Permissions:Admin": "Administrator",
        "Permissions:Member": "Mitglied",
        "Permissions:MemberIsNotPlatinum": "Anpassung exklusiv für Platinum-Abonnenten",
        "Permissions:EveryoneDropdown": "Jeder in diesem Kanal kann {taskName} hinzufügen/bearbeiten/löschen",
        "Permissions:CreatorDropdown": "Nur der/die Besitzer dieses {appTitle} kann {taskName} hinzufügen/bearbeiten/löschen",
        "Permissions:CustomDropdown": "Berechtigungen anpassen",
        "Permissions:Platinum": "Platinum",
        "Permissions:Premium": "<a href='https://admin.teams-pro.com/users' target='_blank'>Premium</a>",
        "Permissions:Free": "<a href='https://admin.teams-pro.com/users' target='_blank'>Kostenlos</a>",
        "Permissions:AssignLicense": "Klicken Sie hier, um eine Platinum-Lizenz zuzuweisen 🚀",
        "Permissions:Subscription": "Abonnement",
        "Booking:taskName": "Buchungen",
        "Parking:taskName": "Buchungen",
        "Calendar:taskName": "Ereignisse",
        "SharedCalendar:taskName": "un évenement",
        "Gantt:taskName": "Aufgaben",
        "Keepass:taskName": "Passwörter",
        "Contact:taskName": "Kontakte und Gruppen",
        AddMembers: "Mitglieder hinzufügen",
        AddMembersTagLine: "Geben Sie die E-Mail-Adresse der Person ein, die Sie hinzufügen möchten",
        EmailAddress: "E-Mail-Adresse eingeben",
        SaveAlert: "Ihre Änderungen wurden erfolgreich gespeichert!",
        CancelTagLine: "Wenn Sie jetzt fortfahren, werden alle von Ihnen vorgenommenen Änderungen verworfen. Möchten Sie wirklich fortfahren?",
        Continue: "Weiter",
        Return: "Zurück",
        "Permissions:FreeRedirect": "Sie verwenden eine kostenlose Version von {appTitle}.",
        "Permissions:PremiumRedirect": "Sie verwenden eine Premium-Version von {appTitle}.",
        "Sync:PremiumRedirect": "Sie verwenden eine Premium-Version von {appTitle}. Klicken Sie <a href='https://admin.teams-pro.com/services' target='_blank'>hier 🚀</a>, um auf Platinum upzugraden und Synchronisierungen zu verwalten!",
        "Sync:FreeRedirect": "Sie verwenden eine kostenlose Version von {appTitle}. Klicken Sie <a href='https://admin.teams-pro.com/services' target='_blank'>hier 🚀</a>, um auf Platinum upzugraden und Synchronisierungen zu verwalten!",
        NotAllowed: "Sie dürfen die Berechtigungen nicht ändern.",
        NotAllowedNotif: "Sie dürfen diese Einstellungen nicht ändern.",
        CancelModifications: "Änderungen abbrechen",
        "Sync:Title": "Kalender synchronisieren",
        "Sync:Manage": "Verwalten",
        "Sync:Synchronize": "Synchronisieren",
        "Sync:Add": "Kalender hinzufügen",
        "Sync:SyncCalendarTitle": "Kalender auf meinem synchronisieren",
        "Sync:ShareTitle": "Teilen Sie mein ",
        "Sync:CalendarName": "Name des Kalenders",
        "Sync:ICSLink": ".ICS-Link des Kalenders",
        "Sync:PublicAddress": "Öffentliche Adresse im iCal-Format",
        "Sync:TableName": "Name",
        "Sync:TableColor": "Farbe",
        "Sync:TableShow": "Teilen",
        "Sync:TableState": "Status",
        "Sync:SynchronizeLine": "Um alle Ihre Kalender an einem Ort zu verwalten, synchronisieren Sie Ihren Kalender.",
        "Sync:SyncCalendarTooltip": "Sie können maximal 3 Kalender synchronisieren. Ihr Kalender wird alle 15 Minuten synchronisiert.",
        "Sync:SyncTitle": "Externer Kalender hinzufügen",
        AddAlert: "Die Mitglieder wurden erfolgreich hinzugefügt",
        "Sync:ICSInput": "Geben Sie eine URL ein",
        "Sync:NameInput": "Geben Sie einen Namen ein",
        "Sync:TableAction": "Aktion",
        "Sync:ManageTooltip": "Sie haben keinen einzigen synchronisierten Kalender",
        AddressTitle: "Adresse",
        AddAddress: "Geben Sie hier eine Adresse ein, um sie zu suchen",
        Participants: "Teilnehmer",
        NoParticipants: "Keine Ergebnisse gefunden",
        "Sync:TabTooltip": "Sie müssen Ihre Änderungen speichern/abbrechen",
        "Sync:DeleteCalendar": "Kalender löschen",
        "Sync:DeleteTagLine": "Wenn Sie jetzt fortfahren, wird Ihr Kalender aus Ihrer Ansicht gelöscht. Um ihn erneut anzuzeigen, müssen Sie ihn erneut synchronisieren.",
        "Sync:NotAllowToShare": "Die Besitzer des Kalenders haben Ihnen nicht erlaubt, Ihre Kalender mit anderen zu teilen",
        "Sync:ConnectionIssue": "Problem mit der Kalenderverbindung festgestellt. Überprüfen Sie die Existenz des Kalenders und den Verbindungsstatus für eine reibungslose Synchronisierung.",
        "Permissions:CreatorRole": "Besitzer dieses Kalenders",
        Synchronized: "Synchronisiert",
        Unsynchronized: "Nicht synchronisiert",
        "Sync:UnsynchronizedTooltip": "Problem mit der Kalenderverbindung festgestellt. Überprüfen Sie die Existenz des Kalenders und den Verbindungsstatus für eine reibungslose Synchronisierung",
        "Sync:ShareInfo": "Teilen Sie diesen Kalender mit Mitgliedern auf",
        AddParticipantsButton: "Teilnehmer hinzufügen",
        "Sync:getErrorMessage": "Es ist ein Fehler mit Ihrem ICS-Link aufgetreten, auf dem Kalender:",
        "Permissions:ManageRoleTitle": "Bestätigung der Rollenänderung",
        "Permissions:ManageRoleTagLine": "Sie sind dabei, die Rolle eines Mitglieds in Ihrem Tab zu ändern. Durch die Fortsetzung gewähren Sie Rechte, die Sie einem Mitglied nicht entziehen können. Möchten Sie wirklich fortfahren?",
        NotifDialogHeader: "Teams-Benachrichtigungseinstellungen",
        RedirectToAppSource: "<p style='text-align: center;'>Wir bringen Sie zum Microsoft Marketplace. Wenn Sie nicht weitergeleitet werden, <a target='_blank' href='{link}'>klicken Sie hier</a>.</p>",
        WhatsNewsOn: "Was gibt's Neues?",
        NewsSoon: "Neuigkeiten kommen bald!",
        NeedConsentTitle: "Zustimmung erforderlich",
        NeedConsent: "Um diese Aktion auszuführen, benötigen wir Ihre Zustimmung. Wenn Sie zustimmen, werden Sie zur Zustimmungsseite weitergeleitet.",
        NoAddress: "Keine Ergebnisse auf Google Maps gefunden",
        YourInfo: "Ihre Informationen",
        ChooseTime: "Datum und Uhrzeit wählen",
        IC_SuperAdminTitle: "Warnung",
        IC_SuperAdminMessage: "Ihre Zahlungsinformationen laufen bald ab. Bitte aktualisieren Sie sie, um einen Serviceausfall zu vermeiden.",
        IC_SuperAdminCTA: "Zum Admin Center gehen",
        IC_AdminTitle: "Warnung",
        IC_AdminMessage: "Die Zahlungsmethode Ihrer zugehörigen Lizenz läuft bald ab.",
        IC_AdminCTA: "Klicken Sie hier, um Ihren Admin zu informieren",
        IC_NotifTitle: "Aufmerksamkeit erforderlich",
        IC_NotifMessage: "Bitte greifen Sie auf {appName} zu, um wichtige Updates zu erhalten",
        PreventUnlockPremiumFeaturesOnMobile: "Besuchen Sie das Admin Center, um Contacts Pro zu aktualisieren.",
        GoToAdminCenter: "Zum Admin Center gehen",
        PremiumMessage1: "Sie verwenden eine Premium-Version von {appTitle}.",
        ClickHere: "Hier klicken 🚀",
        PremiumMessage2: "um auf Platinum zu aktualisieren und Berechtigungen effektiver zu verwalten!",
        DiscardMessageTitle: "Nicht gespeicherte Änderungen",
        DiscardChanges: "Verwerfen",
        ContinueEditing: "Bearbeitung fortsetzen",
        DiscardMessage: "Möchten Sie Änderungen verwerfen oder mit der Bearbeitung fortfahren ?",
        VisitLink: "Den Link besuchen",
        SelectCustomize: "Um Berechtigungen anzupassen, wählen Sie 'Berechtigungen anpassen' oben",
        SaveBeforeSearch: "Speichern Sie Ihre Änderungen, bevor Sie suchen",
        AccessMaster: "Dieser Ben utzer hat Zugriff auf das Master-Passwort",
        "SuperAdmin:NoResult": "Keine Suchergebnisse für",
        FeaturesNowAvailable: "Genießen Sie jetzt alle Ihre neuen Funktionen!",
        KnowAboutFeatures: "Um mehr über die Ihnen zur Verfügung stehenden Funktionen zu erfahren, klicken Sie <a target='_blank' href='{link}'>hier</a>!",
        Go: "Los geht's!",
        NeedToBuyLicences: "Sie müssen Lizenzen kaufen",
        HaveLicencesButNotEnough: "Sie haben bereits einige Lizenzen, aber sie sind alle zugewiesen. Sie müssen mehr Lizenzen kaufen, um sich selbst eine zuweisen zu können.",
        BuyMoreLicence: "Mehr Lizenzen kaufen",
        GeneralPermissionsSettings: "Allgemeine Berechtigungseinstellungen",
        CustomizedPermissionsSettings: "Angepasste Berechtigungseinstellungen",
        About: "Über",
        Version: "Version",
        UniqueID: "Eindeutige Kennung",
        Copied: "Kopiert!",
        Copy: "Kopieren",
        CreatedBy: "Erstellt von",
        SuggestFeature: "Funktion vorschlagen",
        HelpCenter: "Hilfezentrum",
        "MoreApps": "Weitere Apps",
    },
    it: {
        UnlockPremiumFeatures: "Sblocca Premium per abilitare più funzionalità",
        UpgradePremium: "Aggiorna a Premium",
        UpgradePlatinum: "Aggiorna a Platinum",
        Cancel: "Annulla",
        ThanksPurchase: "Grazie per l'acquisto",
        PremiumNowAvailable: "Ora puoi godere di tutte le funzionalità premium",
        ManageLicences: "Gestisci licenze",
        ManageMyLicence: "Gestire la mia licenza",
        Close: "Chiudi",
        RequestAccess: "Richiedi accesso",
        UpgradeTeamMessage: "Passa a Premium per il tuo team. Sarai in grado di gestire i membri che avranno accesso alla versione Premium.",
        UpgradePlatinumTeamMessage: "Passa a Platinum per il tuo team. Sarai in grado di gestire i membri che avranno accesso alla versione Platinum.",
        TeamHasLicences: "La tua azienda ha già effettuato l'upgrade a Premium. Lascia un messaggio all'amministratore per richiedere l'accesso.",
        NoPremiumAccess: "Non hai accesso Premium",
        TeamHasPlatinumLicences: "La tua azienda ha già effettuato l'upgrade a Platinum. Lascia un messaggio all'amministratore per richiedere l'accesso.",
        NoPlatinumAccess: "Non hai accesso Platinum",
        RequestAccessMessage: "Lascia un messaggio all'amministratore per richiedere l'accesso. Verrà notificato e ti verrà concesso l'accesso presto.",
        Send: "Invia",
        AdministratorNotified: "L'amministratore è stato notificato. Riceverai una email quando sarà completato.",
        DefaultRequestMessage: "Ciao, sono {userName} e vorrei avere accesso Premium su {appTitle}. Grazie.",
        RequestAccessInApprobation: "Una richiesta di accesso è in fase di approvazione. Se hai fretta, attiva Premium ora",
        YouAreAdmin: "Sei amministratore",
        YouCanManageLicences: "Hai la possibilità di gestire le licenze per questa applicazione, se lo desideri, clicca sul pulsante qui sotto per assegnarti una licenza Premium.",
        YouCanManageLicencesToPlatinum: "Hai la possibilità di gestire le licenze per questa applicazione, se lo desideri, fai clic sul pulsante in basso per assegnarti una licenza Platinum.",
        SendMessage: "Invia messaggio",
        Call: "Chiama",
        System: "Sistema",
        Unknown: "Sconosciuto",
        TokenExpired: "Token scaduto",
        PleaseRefreshTab: "Aggiorna la scheda per favore",
        AnErrorOccured: "Si è verificato un errore",
        Consent: "Consenso",
        NeedToConsent: "È necessario dare il consenso",
        ClickButtonToContinue: "Clicca sul pulsante per continuare",
        SearchATeam: "Cerca un team",
        JoinTeam: "Unisciti al team",
        Teams: "Team",
        EditTextConfirm: "Salva",
        EditTextEdit: "Modifica",
        EditTextRemove: "Rimuovi",
        EditTextLink: "Link",
        "Permissions:Title": "Permessi",
        "Permissions:Search": "Cerca membri",
        "Permissions:TableName": "Nome",
        "Permissions:TableRole": "Ruolo",
        "Calendar:Permissions:Tableadd": "Aggiungi",
        "Calendar:Permissions:Tableupdate": "Modifica",
        "Calendar:Permissions:Tabledelete": "Elimina",
        "Calendar:Permissions:TableallowShareSynchro": "Condivisione calendario",
        "Calendar:Permissions:TableallowEditMeetingScheduler": "Pianificazione riunioni",
        "SharedCalendar:Permissions:Tableadd": "Aggiungi",
        "SharedCalendar:Permissions:Tableupdate": "Modifica",
        "SharedCalendar:Permissions:Tabledelete": "Elimina",
        "SharedCalendar:Permissions:TableallowShareSynchro": "Condivisione calendario",
        "SharedCalendar:Permissions:TableallowEditMeetingScheduler": "Pianificazione riunioni",
        "Booking:Permissions:Tableadd": "Aggiungi",
        "Booking:Permissions:Tableupdate": "Modifica",
        "Booking:Permissions:Tabledelete": "Elimina",
        "Parking:Permissions:Tableadd": "Aggiungi",
        "Parking:Permissions:Tableupdate": "Modifica",
        "Parking:Permissions:Tabledelete": "Elimina",
        "Contact:Permissions:Tableadd": "Aggiungi",
        "Contact:Permissions:Tableupdate": "Modifica",
        "Contact:Permissions:Tabledelete": "Elimina",
        "Keepass:Permissions:Tableadd": "Aggiungi",
        "Keepass:Permissions:Tableupdate": "Modifica",
        "Keepass:Permissions:Tabledelete": "Elimina / Ripristina",
        "Permissions:CancelButton": "Annulla",
        "Permissions:SaveButton": "Salva",
        "Permissions:AddMemberButton": "Aggiungi membro",
        "Permissions:GeneralSetting": "Permessi predefiniti",
        "Calendar:Permissions:addInfo": "L'utente sarà in grado di aggiungere eventi",
        "Calendar:Permissions:updateInfo": "L'utente sarà in grado di modificare eventi",
        "Calendar:Permissions:deleteInfo": "L'utente sarà in grado di eliminare eventi",
        "Calendar:Permissions:allowEditMeetingSchedulerInfo": "L'utente sarà in grado di gestire le impostazioni della pianificazione riunioni",
        "Calendar:Permissions:allowShareSynchroInfo": "L'utente sarà in grado di condividere i suoi calendari sincronizzati con tutti",
        "SharedCalendar:Permissions:addInfo": "L'utente sarà in grado di aggiungere eventi",
        "SharedCalendar:Permissions:updateInfo": "L'utente sarà in grado di modificare eventi",
        "SharedCalendar:Permissions:deleteInfo": "L'utente sarà in grado di eliminare eventi",
        "SharedCalendar:Permissions:allowEditMeetingSchedulerInfo": "L'utente sarà in grado di gestire le impostazioni della pianificazione riunioni",
        "SharedCalendar:Permissions:allowShareSynchroInfo": "L'utente sarà in grado di condividere i suoi calendari sincronizzati con tutti",
        "Booking:Permissions:addInfo": "L'utente sarà in grado di aggiungere prenotazioni",
        "Booking:Permissions:updateInfo": "L'utente sarà in grado di modificare prenotazioni",
        "Booking:Permissions:deleteInfo": "L'utente sarà in grado di eliminare prenotazioni",
        "Parking:Permissions:addInfo": "L'utente sarà in grado di aggiungere prenotazioni",
        "Parking:Permissions:updateInfo": "L'utente sarà in grado di modificare prenotazioni",
        "Parking:Permissions:deleteInfo": "L'utente sarà in grado di eliminare prenotazioni",
        "Contact:Permissions:addInfo": "L'utente potrà aggiungere contatti e gruppi",
        "Contact:Permissions:updateInfo": "L'utente potrà modificare contatti e gruppi",
        "Contact:Permissions:deleteInfo": "L'utente potrà eliminare contatti e gruppi",
        "Keepass:Permissions:addInfo": "L'utente potrà aggiungere password",
        "Keepass:Permissions:updateInfo": "L'utente potrà modificare password",
        "Keepass:Permissions:deleteInfo": "L'utente potrà eliminare e ripristinare password",
        "Permissions:Owner": "Proprietario",
        "Permissions:Admin": "Amministratore",
        "Permissions:Member": "Membro",
        "Permissions:MemberIsNotPlatinum": "Personalizzazione esclusiva per gli abbonati Platinum",
        "Permissions:EveryoneDropdown": "Tutti in questo canale possono aggiungere/modificare/eliminare {taskName}",
        "Permissions:CreatorDropdown": "Solo il/i proprietario/i di questo {appTitle} possono aggiungere/modificare/eliminare {taskName}",
        "Permissions:CustomDropdown": "Personalizza i permessi",
        "Permissions:Platinum": "Platinum",
        "Permissions:Premium": "<a href='https://admin.teams-pro.com/users' target='_blank'>Premium</a>",
        "Permissions:Free": "<a href='https://admin.teams-pro.com/users' target='_blank'>Gratuito</a>",
        "Permissions:AssignLicense": "Clicca per assegnare una licenza Platinum 🚀",
        "Permissions:Subscription": "Abbonamento",
        "Booking:taskName": "prenotazioni",
        "Parking:taskName": "prenotazioni",
        "Calendar:taskName": "eventi",
        "SharedCalendar:taskName": "eventi",
        "Gantt:taskName": "attività",
        "Contact:taskName": "contatti e gruppi",
        "Keepass:taskName": "password",
        AddMembers: "Aggiungi membri",
        AddMembersTagLine: "Inizia a digitare l'indirizzo email della persona che desideri aggiungere",
        EmailAddress: "Inserisci l'indirizzo email",
        SaveAlert: "Le tue modifiche sono state salvate con successo!",
        CancelTagLine: "Se procedi ora, qualsiasi modifica apportata verrà annullata. Sei sicuro di voler continuare?",
        Continue: "Continua",
        Return: "Torna",
        "Permissions:FreeRedirect": "Stai utilizzando una versione gratuita di {appTitle}.",
        "Permissions:PremiumRedirect": "Stai utilizzando una versione premium di {appTitle}.",
        "Sync:PremiumRedirect": "Stai utilizzando una versione premium di {appTitle}. Fai clic <a href='https://admin.teams-pro.com/services' target='_blank'>qui 🚀</a> per passare a Platinum e gestire le sincronizzazioni!",
        "Sync:FreeRedirect": "Stai utilizzando una versione gratuita di {appTitle}. Fai clic <a href='https://admin.teams-pro.com/services' target='_blank'>qui 🚀</a> per passare a Platinum e gestire le sincronizzazioni!",
        NotAllowed: "Non ti è consentito modificare i permessi.",
        NotAllowedNotif: "Non ti è consentito modificare questa impostazione.",
        CancelModifications: "Annulla modifiche",
        "Sync:Title": "Sincronizza calendari",
        "Sync:Manage": "Gestisci",
        "Sync:Synchronize": "Sincronizza",
        "Sync:Add": "Aggiungi un calendario",
        "Sync:SyncCalendarTitle": "Sincronizza calendari con il mio ",
        "Sync:ShareTitle": "Condividi il mio ",
        "Sync:CalendarName": "Nome del calendario",
        "Sync:ICSLink": "Link .ICS del calendario",
        "Sync:PublicAddress": "Indirizzo pubblico formato iCal",
        "Sync:TableName": "Nome",
        "Sync:TableColor": "Colore",
        "Sync:TableShow": "Condividi",
        "Sync:TableState": "Stato",
        "Sync:SynchronizeLine": "Per gestire tutti i tuoi calendari in un unico posto, sincronizza il tuo calendario.",
        "Sync:SyncCalendarTooltip": "Puoi sincronizzare al massimo 3 calendari. Il tuo calendario sarà sincronizzato ogni 15 minuti.",
        "Sync:SyncTitle": "Aggiungi calendario esterno",
        AddAlert: "I membri sono stati aggiunti con successo",
        "Sync:ICSInput": "Digita un URL",
        "Sync:NameInput": "Digita un nome",
        "Sync:TableAction": "Azione",
        "Sync:ManageTooltip": "Non hai un singolo calendario sincronizzato",
        AddressTitle: "Indirizzo",
        AddAddress: "Digita qui per cercare un indirizzo",
        Participants: "Partecipanti",
        NoParticipants: "Nessun risultato trovato",
        "Sync:TabTooltip": "Devi salvare/annullare le tue modifiche",
        "Sync:DeleteCalendar": "Elimina calendario",
        "Sync:DeleteTagLine": "Se procedi ora, il tuo calendario verrà eliminato dalla tua visualizzazione. Per vederlo di nuovo, dovrai sincronizzarlo nuovamente.",
        "Sync:NotAllowToShare": "I proprietari del calendario non ti permettono di condividere i tuoi calendari con gli altri",
        "Sync:ConnectionIssue": "Rilevato problema di connessione al calendario. Verifica l'esistenza del calendario e lo stato della connessione per una sincronizzazione senza problemi.",
        "Permissions:CreatorRole": "Proprietario di questo calendario",
        Synchronized: "Sincronizzato",
        Unsynchronized: "Non sincronizzato",
        "Sync:UnsynchronizedTooltip": "Rilevato problema di connessione al calendario. Verifica l'esistenza del calendario e lo stato della connessione per una sincronizzazione senza problemi.",
        "Sync:ShareInfo": "Condividi questo calendario con i membri su ",
        AddParticipantsButton: "Aggiungi partecipanti",
        "Sync:getErrorMessage": "Si è verificato un errore con il tuo link ICS sul calendario: ",
        "Permissions:ManageRoleTitle": "Conferma cambio ruolo",
        "Permissions:ManageRoleTagLine": "Stai per cambiare il ruolo di un membro nella tua scheda. Procedendo, concederai diritti che non sarai in grado di revocare da un membro. Sei sicuro di voler continuare?",
        NotifDialogHeader: "Impostazioni notifica di Teams",
        RedirectToAppSource: "<p style='text-align: center;'>Ti stiamo reindirizzando al Marketplace di Microsoft. Se non sei reindirizzato, <a target='_blank' href='{link}'>clicca qui</a>.</p>",
        WhatsNewsOn: "Novità",
        NewsSoon: "Le novità arriveranno presto!",
        NeedConsentTitle: "Necessità di consenso",
        NeedConsent: "Per eseguire questa azione avremo bisogno del consenso. Se confermi, verrai reindirizzato alla pagina di consenso.",
        NoAddress: "Nessun risultato trovato su Google Maps",
        YourInfo: "Le tue informazioni",
        ChooseTime: "Scegli data e ora",
        IC_SuperAdminTitle: "Attenzione",
        IC_SuperAdminMessage: "Le tue informazioni di pagamento stanno per scadere, ti preghiamo di aggiornarle per evitare interruzioni del servizio.",
        IC_SuperAdminCTA: "Vai al Centro amministrativo",
        IC_AdminTitle: "Attenzione",
        IC_AdminMessage: "Il metodo di pagamento della tua licenza associata sta per scadere.",
        IC_AdminCTA: "Clicca qui per informare il tuo amministratore",
        IC_NotifTitle: "Attenzione richiesta",
        IC_NotifMessage: "Accedi a {appName} per aggiornamenti importanti",
        IC_SuccesNotifTitle: "Successo",
        IC_SuccesNotifMessage: "La notifica è stata inviata con successo al tuo Super Admin.",
        PreventUnlockPremiumFeaturesOnMobile: "Visita il Centro Amministrativo per aggiornare a Contacts Pro.",
        GoToAdminCenter: "Vai al Centro Amministrativo",
        PremiumMessage1: "Stai utilizzando una versione premium di {appTitle}.",
        ClickHere: "Clicca qui 🚀",
        PremiumMessage2: "per passare a Platinum e gestire le autorizzazioni in modo più efficace!",
        DiscardMessageTitle: "Modifiche non salvate",
        DiscardChanges: "Rimuovi",
        ContinueEditing: "Continua la modifica",
        DiscardMessage: "Vuoi continuare ad apportare modifiche o rimuoverle ?",
        VisitLink: "Visita il link",
        SelectCustomize: "Per personalizzare i permessi, seleziona 'Personalizza permessi' in alto",
        SaveBeforeSearch: "Salva le tue modifiche prima di cercare",
        AccessMaster: "Questo utente ha accesso alla password principale",
        "SuperAdmin:NoResult": "Nessun risultato per",
        FeaturesNowAvailable: "Goditi tutte le tue nuove funzionalità ora!",
        KnowAboutFeatures: "Per saperne di più sulle funzionalità disponibili, clicca <a target='_blank' href='{link}'>qui</a>!",
        Go: "Andiamo!",
        NeedToBuyLicences: "Devi acquistare delle licenze",
        HaveLicencesButNotEnough: "Hai già alcune licenze, ma sono tutte assegnate. Devi acquistare altre licenze per assegnarne una a te stesso.",
        BuyMoreLicence: "Acquista più licenze",
        GeneralPermissionsSettings: "Impostazioni delle autorizzazioni generali",
        CustomizedPermissionsSettings: "Impostazioni delle autorizzazioni personalizzate",
        About: "Informazioni",
        Version: "Versione",
        UniqueID: "Identificatore univoco",
        Copied: "Copiato!",
        Copy: "Copia",
        CreatedBy: "Creato da",
        SuggestFeature: "Suggerisci una funzione",
        HelpCenter: "Centro assistenza",
        "MoreApps": "Altre app",
    },
    es: {
        SendMessage: "Enviar mensaje",
        Call: "Llamar",
        System: "Sistema",
        Unknown: "Desconocido",
        TokenExpired: "Token expirado",
        PleaseRefreshTab: "Por favor, actualice la pestaña",
        AnErrorOccured: "Se produjo un error",
        Consent: "Consentimiento",
        NeedToConsent: "Debe dar su consentimiento",
        ClickButtonToContinue: "Haga clic en el botón para continuar",
        SearchATeam: "Buscar un equipo",
        JoinTeam: "Unirse al equipo",
        Teams: "Equipos",
        EditTextConfirm: "Guardar",
        EditTextEdit: "Editar",
        EditTextRemove: "Eliminar",
        EditTextLink: "Enlace",
        UnlockPremiumFeatures: "Desbloquear funciones Premium",
        UpgradePremium: "Actualizar a Premium",
        UpgradePlatinum: "Actualizar a Platinum",
        Cancel: "Cancelar",
        ThanksPurchase: "Gracias por su compra",
        PremiumNowAvailable: "Ahora puede disfrutar de todas las funciones premium",
        ManageLicences: "Gestionar licencias",
        ManageMyLicence: "Gestionar mi licencia",
        Close: "Cerrar",
        RequestAccess: "Solicitar acceso",
        UpgradeTeamMessage: "Active la versión Premium para su equipo. Tendrá la capacidad de gestionar los miembros que tendrán acceso a la versión Premium.",
        UpgradePlatinumTeamMessage: "Active la versión Platinum para su equipo. Tendrá la capacidad de gestionar los miembros que tendrán acceso a la versión Platinum.",
        TeamHasLicences: "Su empresa ya tiene acceso a la versión Premium. Envíe un mensaje al administrador para solicitar acceso.",
        NoPremiumAccess: "No tiene acceso Premium",
        TeamHasPlatinumLicences: "Su empresa ya tiene acceso a la versión Platinum. Envíe un mensaje al administrador para solicitar acceso.",
        NoPlatinumAccess: "No tiene acceso Platinum",
        RequestAccessMessage: "Deje un mensaje al administrador para su solicitud de acceso. Será notificado y se le otorgará acceso pronto.",
        Send: "Enviar",
        AdministratorNotified: "Se ha notificado al administrador. Recibirá un correo electrónico cuando se apruebe su solicitud.",
        DefaultRequestMessage: "Hola, soy {userName} y me gustaría tener acceso a la versión Premium de {appTitle}. Gracias.",
        RequestAccessInApprobation: "Se está procesando una solicitud de acceso. Si tiene prisa, active el Premium ahora",
        YouAreAdmin: "Eres administrador",
        YouCanManageLicences: "Tiene la capacidad de gestionar licencias para esta aplicación. Si lo desea, haga clic en el botón a continuación para asignarse una licencia Premium.",
        YouCanManageLicencesToPlatinum: "Tiene la posibilidad de gestionar licencias para esta aplicación, si lo desea, haga clic en el botón de abajo para asignarse una licencia Platinum.",
        "Permissions:Title": "Permisos",
        "Permissions:Search": "Buscar miembros",
        "Permissions:TableName": "Nombre",
        "Permissions:TableRole": "Rol",
        "Calendar:Permissions:Tableadd": "Agregar",
        "Calendar:Permissions:Tableupdate": "Modificar",
        "Calendar:Permissions:Tabledelete": "Eliminar",
        "Calendar:Permissions:TableallowShareSynchro": "Compartir calendario",
        "Calendar:Permissions:TableallowEditMeetingScheduler": "Plataforma de programación de reuniones",
        "SharedCalendar:Permissions:Tableadd": "Agregar",
        "SharedCalendar:Permissions:Tableupdate": "Modificar",
        "SharedCalendar:Permissions:Tabledelete": "Eliminar",
        "SharedCalendar:Permissions:TableallowShareSynchro": "Compartir calendario",
        "SharedCalendar:Permissions:TableallowEditMeetingScheduler": "Plataforma de programación de reuniones",
        "Booking:Permissions:Tableadd": "Agregar",
        "Booking:Permissions:Tableupdate": "Modificar",
        "Booking:Permissions:Tabledelete": "Eliminar",
        "Parking:Permissions:Tableadd": "Agregar",
        "Parking:Permissions:Tableupdate": "Modificar",
        "Parking:Permissions:Tabledelete": "Eliminar",
        "Contact:Permissions:Tableadd": "Agregar",
        "Contact:Permissions:Tableupdate": "Modificar",
        "Contact:Permissions:Tabledelete": "Eliminar",
        "Keepass:Permissions:Tableadd": "Agregar",
        "Keepass:Permissions:Tableupdate": "Modificar",
        "Keepass:Permissions:Tabledelete": "Eliminar / Restaurar",
        "Permissions:CancelButton": "Cancelar",
        "Permissions:SaveButton": "Guardar",
        "Permissions:AddMemberButton": "Agregar miembro",
        "Permissions:GeneralSetting": "Configuración general",
        "Calendar:Permissions:addInfo": "El usuario podrá agregar eventos",
        "Calendar:Permissions:updateInfo": "El usuario podrá modificar eventos",
        "Calendar:Permissions:deleteInfo": "El usuario podrá eliminar eventos",
        "Calendar:Permissions:allowEditMeetingSchedulerInfo": "El usuario podrá gestionar la configuración de la plataforma de programación de reuniones",
        "Calendar:Permissions:allowShareSynchroInfo": "El usuario podrá compartir sus calendarios sincronizados con todos",
        "SharedCalendar:Permissions:addInfo": "El usuario podrá agregar eventos",
        "SharedCalendar:Permissions:updateInfo": "El usuario podrá modificar eventos",
        "SharedCalendar:Permissions:deleteInfo": "El usuario podrá eliminar eventos",
        "SharedCalendar:Permissions:allowEditMeetingSchedulerInfo": "El usuario podrá gestionar la configuración de la plataforma de programación de reuniones",
        "SharedCalendar:Permissions:allowShareSynchroInfo": "El usuario podrá compartir sus calendarios sincronizados con todos",
        "Booking:Permissions:addInfo": "El usuario podrá agregar reservas",
        "Booking:Permissions:updateInfo": "El usuario podrá modificar reservas",
        "Booking:Permissions:deleteInfo": "El usuario podrá eliminar reservas",
        "Parking:Permissions:addInfo": "El usuario podrá agregar reservas",
        "Parking:Permissions:updateInfo": "El usuario podrá modificar reservas",
        "Parking:Permissions:deleteInfo": "El usuario podrá eliminar reservas",
        "Contact:Permissions:addInfo": "El usuario podrá añadir contactos y grupos",
        "Contact:Permissions:updateInfo": "El usuario podrá modificar contactos y grupos",
        "Contact:Permissions:deleteInfo": "El usuario podrá eliminar contactos y grupos",
        "Keepass:Permissions:addInfo": "El usuario podrá añadir contraseñas",
        "Keepass:Permissions:updateInfo": "El usuario podrá modificar contraseñas",
        "Keepass:Permissions:deleteInfo": "El usuario podrá eliminar y restaurar contraseñas",
        "Permissions:Owner": "Propietario",
        "Permissions:Admin": "Administrador",
        "Permissions:Member": "Miembro",
        "Permissions:MemberIsNotPlatinum": "Personalización reservada para suscriptores Platinum",
        "Permissions:EveryoneDropdown": "Todos en este canal pueden agregar/editar/eliminar {taskName}",
        "Permissions:CreatorDropdown": "Solo el/los propietario(s) de {appTitle} puede agregar/editar/eliminar {taskName}",
        "Permissions:CustomDropdown": "Personalizar permisos",
        "Permissions:Platinum": "Platinum",
        "Permissions:Premium": "<a href='https://admin.teams-pro.com/users' target='_blank'>Premium</a>",
        "Permissions:Free": "<a href='https://admin.teams-pro.com/users' target='_blank'>Gratis</a>",
        "Permissions:AssignLicense": "Haga clic para asignar una licencia Platinum 🚀",
        "Permissions:Subscription": "Suscripción",
        AddMembers: "Agregar miembros",
        AddMembersTagLine: "Ingrese el correo electrónico de la persona que desea agregar",
        EmailAddress: "Ingrese una dirección de correo electrónico",
        SaveAlert: "¡Sus cambios se han guardado exitosamente!",
        CancelTagLine: "Si continúa ahora, se perderán todos los cambios que haya realizado. ¿Está seguro de que desea continuar?",
        Continue: "Continuar",
        Return: "Volver",
        "Permissions:FreeRedirect": "Está utilizando una versión gratuita de {appTitle}.",
        "Permissions:PremiumRedirect": "Está utilizando una versión premium de {appTitle}.",
        "Booking:taskName": "una reserva",
        "Parking:taskName": "una reserva",
        "Calendar:taskName": "un evento",
        "SharedCalendar:taskName": "un evento",
        "Contact:taskName": "contactos y grupos",
        "Gantt:taskName": "una tarea",
        "Keepass:taskName": "contraseñas",
        "Sync:PremiumRedirect": "Está utilizando una versión premium de Calendar Pro. Haga clic <a href='https://admin.teams-pro.com/services' target='_blank'>aquí 🚀</a> para cambiar a la versión Platinum y gestionar las sincronizaciones.",
        "Sync:FreeRedirect": "Está utilizando una versión gratuita de Calendar Pro. Haga clic <a href='https://admin.teams-pro.com/services' target='_blank'>aquí 🚀</a> para cambiar a la versión Platinum y gestionar las sincronizaciones.",
        NotAllowed: "No tiene permitido cambiar los permisos.",
        NotAllowedNotif: "No tiene permitido cambiar esta configuración.",
        CancelModifications: "Cancelar modificaciones",
        "Sync:Title": "Sincronizar calendarios",
        "Sync:Manage": "Gestionar",
        "Sync:Synchronize": "Sincronizar",
        "Sync:Add": "Agregar calendario",
        "Sync:SyncCalendarTitle": "Sincronizar mi calendario con ",
        "Sync:ShareTitle": "Compartir mi ",
        "Sync:CalendarName": "Nombre del calendario",
        "Sync:ICSLink": "Enlace ICS del calendario",
        "Sync:PublicAddress": "Dirección pública en formato iCal",
        "Sync:TableName": "Nombre",
        "Sync:TableColor": "Color",
        "Sync:TableShow": "Compartir",
        "Sync:TableState": "Estado",
        "Sync:SynchronizeLine": "Para administrar todos sus calendarios en un solo lugar, sincronice su calendario.",
        "Sync:SyncCalendarTooltip": "Puede sincronizar un máximo de 3 calendarios. Su calendario se sincronizará cada 15 minutos.",
        "Sync:SyncTitle": "Agregar sus calendarios externos",
        AddAlert: "Los miembros se han agregado correctamente",
        "Sync:ICSInput": "Ingrese una URL",
        "Sync:NameInput": "Ingrese un nombre",
        "Sync:TableAction": "Acción",
        "Sync:ManageTooltip": "No tiene calendarios sincronizados",
        AddressTitle: "Dirección",
        AddAddress: "Ingrese una dirección",
        Participants: "Participantes",
        NoParticipants: "Sin resultados",
        "Sync:TabTooltip": "Debe guardar/cancelar sus cambios",
        "Sync:DeleteCalendar": "Eliminar calendario",
        "Sync:DeleteTagLine": "Si continúa ahora, su calendario se eliminará de su vista. Para verlo nuevamente, deberá sincronizarlo nuevamente.",
        "Sync:NotAllowToShare": "El propietario del calendario no le ha permitido compartir sus calendarios con otros usuarios",
        "Sync:ConnectionIssue": "Se ha detectado un problema de conexión con el calendario. Verifique la existencia del calendario y el estado de la conexión para una sincronización sin problemas.",
        "Permissions:CreatorRole": "Propietario de este calendario",
        Synchronized: "Sincronizado",
        Unsynchronized: "No sincronizado",
        "Sync:UnsynchronizedTooltip": "Se ha detectado un problema de conexión con el calendario. Verifique la existencia del calendario y el estado de la conexión para una sincronización sin problemas.",
        "Sync:ShareInfo": "Comparta este calendario con los miembros de ",
        AddParticipantsButton: "Agregar participantes",
        "Sync:getErrorMessage": "Se produjo un error con su enlace ICS en el calendario: ",
        "Permissions:ManageRoleTitle": "Confirmación del cambio de rol",
        "Permissions:ManageRoleTagLine": "Está a punto de cambiar el rol de un miembro. Esto otorgará permisos que no podrá quitarle a un miembro. ¿Está seguro de que desea continuar?",
        NotifDialogHeader: "Configuración de notificaciones de Teams",
        RedirectToAppSource: "<p style='text-align: center;'>Será redirigido al Microsoft Marketplace. Si no es redirigido, <a target='_blank' href='{link}'>haga clic aquí</a>.</a>",
        WhatsNewsOn: "Novedades",
        NewsSoon: "¡Próximamente novedades!",
        NeedConsentTitle: "Se requiere consentimiento",
        NeedConsent: "Para utilizar esta función, se requieren permisos adicionales. Si acepta, se lo redirigirá a la página de consentimiento.",
        NoAddress: "No se encontraron resultados en Google Maps",
        YourInfo: "Tus información",
        ChooseTime: "Elegir fecha y hora",
        IC_SuperAdminTitle: "Atención",
        IC_SuperAdminMessage: "Su información de pago está a punto de caducar. Por favor, actualícela para evitar una interrupción del servicio.",
        IC_SuperAdminCTA: "Ir al Centro de administración",
        IC_AdminTitle: "Atención",
        IC_AdminMessage: "El método de pago de su licencia asociada está a punto de caducar.",
        IC_AdminCTA: "Haga clic aquí para informar a su administrador",
        IC_NotifTitle: "Atención requerida",
        IC_NotifMessage: "Acceda a {appName} para obtener actualizaciones importantes",
        IC_SuccesNotifTitle: "Éxito",
        IC_SuccesNotifMessage: "La notificación se ha enviado correctamente a su Super Admin.",
        PreventUnlockPremiumFeaturesOnMobile: "Visita el Centro de administración para actualizar a Contacts Pro.",
        GoToAdminCenter: "Ir al Centro de administración",
        PremiumMessage1: "Estás utilizando una versión premium de {appTitle}.",
        ClickHere: "Haz clic aquí 🚀",
        PremiumMessage2: "para actualizar a Platinum y gestionar permisos de manera más efectiva!",
        DiscardMessageTitle: "Cambios no guardados",
        DiscardChanges: "Descartar",
        ContinueEditing: "Continuar la edición",
        DiscardMessage: "¿Deseas descartar las modificaciones o continuar con la edición?",
        VisitLink: "Visitar el enlace",
        SelectCustomize: "Para personalizar los permisos, selecciona 'Personalizar permisos' arriba",
        SaveBeforeSearch: "Guarda tus cambios antes de buscar",
        AccessMaster: "Este usuario tiene acceso a la contraseña maestra",
        "SuperAdmin:NoResult": "No hay resultados para",
        FeaturesNowAvailable: "¡Disfruta de todas tus nuevas características ahora!",
        KnowAboutFeatures: "Para saber más sobre las funciones disponibles para ti, haz clic <a target='_blank' href='{link}'>aquí</a>!",
        Go: "¡Vamos!",
        NeedToBuyLicences: "Necesitas comprar licencias",
        HaveLicencesButNotEnough: "Ya tienes algunas licencias, pero todas están asignadas. Debes comprar más licencias para asignarte una a ti mismo.",
        BuyMoreLicence: "Comprar más licencias",
        GeneralPermissionsSettings: "Configuración general de permisos",
        CustomizedPermissionsSettings: "Configuración personalizada de permisos",
        About: "Acerca de",
        Version: "Versión",
        UniqueID: "Identificador único",
        Copied: "Copiado!",
        Copy: "Copiar",
        CreatedBy: "Creado por",
        SuggestFeature: "Sugerir una característica",
        HelpCenter: "Centro de ayuda",
        "MoreApps": "Más aplicaciones",
    },
    pt: {
        UnlockPremiumFeatures: "Desbloqueie o Premium para ativar mais recursos",
        UpgradePremium: "Atualizar para Premium",
        UpgradePlatinum: "Atualizar para Platinum",
        Cancel: "Cancelar",
        ThanksPurchase: "Obrigado pela sua compra",
        PremiumNowAvailable: "Agora você pode desfrutar de todos os recursos premium",
        ManageLicences: "Gerenciar licenças",
        ManageMyLicence: "Gerenciar minha licença",
        Close: "Fechar",
        RequestAccess: "Solicitar acesso",
        UpgradeTeamMessage: "Atualize para Premium para sua própria equipe. Você poderá gerenciar os membros que terão acesso à versão Premium.",
        UpgradePlatinumTeamMessage: "Atualize para Platinum para sua própria equipe. Você poderá gerenciar os membros que terão acesso à versão Platinum.",
        TeamHasLicences: "Sua empresa já atualizou para Premium. Deixe uma mensagem para o administrador solicitar acesso.",
        NoPremiumAccess: "Você não tem acesso premium",
        TeamHasPlatinumLicences: "Sua empresa já atualizou para Platinum. Deixe uma mensagem para o administrador solicitar acesso.",
        NoPlatinumAccess: "Você não tem acesso Platinum",
        RequestAccessMessage: "Deixe uma mensagem para o administrador solicitar acesso. Será notificado e receberá acesso em breve.",
        Send: "Enviar",
        AdministratorNotified: "O administrador foi notificado. Você receberá um e-mail quando estiver pronto.",
        DefaultRequestMessage: "Olá, sou {userName} e gostaria de ter acesso Premium em {appTitle}. Obrigado.",
        RequestAccessInApprobation: "Uma solicitação de acesso está sendo aprovada. Se estiver com pressa, ative o Premium agora",
        YouAreAdmin: "Você é administrador",
        YouCanManageLicences: "Você tem a possibilidade de gerenciar as licenças para este aplicativo, se desejar, clique no botão abaixo para se atribuir uma licença Premium.",
        YouCanManageLicencesToPlatinum: "Você tem a possibilidade de gerenciar licenças para este aplicativo. Se desejar, clique no botão abaixo para se atribuir uma licença Platinum.",
        SendMessage: "Enviar mensagem",
        Call: "Ligar",
        System: "Sistema",
        Unknown: "Desconhecido",
        TokenExpired: "Token expirado",
        PleaseRefreshTab: "Por favor, atualize a aba",
        AnErrorOccured: "Ocorreu um erro",
        Consent: "Consentimento",
        NeedToConsent: "Precisa consentir",
        ClickButtonToContinue: "Clique no botão para continuar",
        SearchATeam: "Pesquisar uma equipe",
        JoinTeam: "Participar da equipe",
        Teams: "Equipes",
        EditTextConfirm: "Salvar",
        EditTextEdit: "Editar",
        EditTextRemove: "Remover",
        EditTextLink: "Link",
        "Permissions:Title": "Permissões",
        "Permissions:Search": "Buscar membros",
        "Permissions:TableName": "Nome",
        "Permissions:TableRole": "Função",
        "Calendar:Permissions:Tableadd": "Adicionar",
        "Calendar:Permissions:Tableupdate": "Modificar",
        "Calendar:Permissions:Tabledelete": "Excluir",
        "Calendar:Permissions:TableallowShareSynchro": "Compartilhamento de Calendário",
        "Calendar:Permissions:TableallowEditMeetingScheduler": "Agendador de Reuniões",
        "SharedCalendar:Permissions:Tableadd": "Adicionar",
        "SharedCalendar:Permissions:Tableupdate": "Modificar",
        "SharedCalendar:Permissions:Tabledelete": "Excluir",
        "SharedCalendar:Permissions:TableallowShareSynchro": "Compartilhamento de Calendário",
        "SharedCalendar:Permissions:TableallowEditMeetingScheduler": "Agendador de Reuniões",
        "Booking:Permissions:Tableadd": "Adicionar",
        "Booking:Permissions:Tableupdate": "Modificar",
        "Booking:Permissions:Tabledelete": "Excluir",
        "Parking:Permissions:Tableadd": "Adicionar",
        "Parking:Permissions:Tableupdate": "Modificar",
        "Parking:Permissions:Tabledelete": "Excluir",
        "Contact:Permissions:Tableadd": "Adicionar",
        "Contact:Permissions:Tableupdate": "Modificar",
        "Contact:Permissions:Tabledelete": "Excluir",
        "Keepass:Permissions:Tableadd": "Adicionar",
        "Keepass:Permissions:Tableupdate": "Modificar",
        "Keepass:Permissions:Tabledelete": "Excluir / Restaurar",
        "Permissions:CancelButton": "Cancelar",
        "Permissions:SaveButton": "Salvar",
        "Permissions:AddMemberButton": "Adicionar membro",
        "Permissions:GeneralSetting": "Permissões padrão",
        "Calendar:Permissions:addInfo": "O usuário poderá adicionar eventos",
        "Calendar:Permissions:updateInfo": "O usuário poderá modificar eventos",
        "Calendar:Permissions:deleteInfo": "O usuário poderá excluir eventos",
        "Calendar:Permissions:allowEditMeetingSchedulerInfo": "O usuário poderá gerenciar as configurações do agendador de reuniões",
        "Calendar:Permissions:allowShareSynchroInfo": "O usuário poderá compartilhar seus calendários sincronizados com todos",
        "SharedCalendar:Permissions:addInfo": "O usuário poderá adicionar eventos",
        "SharedCalendar:Permissions:updateInfo": "O usuário poderá modificar eventos",
        "SharedCalendar:Permissions:deleteInfo": "O usuário poderá excluir eventos",
        "SharedCalendar:Permissions:allowEditMeetingSchedulerInfo": "O usuário poderá gerenciar as configurações do agendador de reuniões",
        "SharedCalendar:Permissions:allowShareSynchroInfo": "O usuário poderá compartilhar seus calendários sincronizados com todos",
        "Booking:Permissions:addInfo": "O usuário poderá adicionar reservas",
        "Booking:Permissions:updateInfo": "O usuário poderá modificar reservas",
        "Booking:Permissions:deleteInfo": "O usuário poderá excluir reservas",
        "Parking:Permissions:addInfo": "O usuário poderá adicionar reservas",
        "Parking:Permissions:updateInfo": "O usuário poderá modificar reservas",
        "Parking:Permissions:deleteInfo": "O usuário poderá excluir reservas",
        "Contact:Permissions:addInfo": "O usuário poderá adicionar contatos e grupos",
        "Contact:Permissions:updateInfo": "O usuário poderá modificar contatos e grupos",
        "Contact:Permissions:deleteInfo": "O usuário poderá excluir contatos e grupos",
        "Keepass:Permissions:addInfo": "O usuário poderá adicionar senhas",
        "Keepass:Permissions:updateInfo": "O usuário poderá modificar senhas",
        "Keepass:Permissions:deleteInfo": "O usuário poderá excluir e restaurar senhas",
        "Permissions:Owner": "Proprietário",
        "Permissions:Admin": "Administrador",
        "Permissions:Member": "Membro",
        "Permissions:MemberIsNotPlatinum": "Personalização exclusiva para assinantes Platinum",
        "Permissions:EveryoneDropdown": "Todos neste canal podem adicionar/editar/excluir {taskName}",
        "Permissions:CreatorDropdown": "Apenas o(s) proprietário(s) deste {appTitle} podem adicionar/editar/excluir {taskName}",
        "Permissions:CustomDropdown": "Personalizar permissões",
        "Permissions:Platinum": "Platinum",
        "Permissions:Premium": "<a href='https://admin.teams-pro.com/users' target='_blank'>Premium</a>",
        "Permissions:Free": "<a href='https://admin.teams-pro.com/users' target='_blank'>Grátis</a>",
        "Permissions:AssignLicense": "Clique para atribuir uma licença Platinum 🚀",
        "Permissions:Subscription": "Assinatura",
        "Booking:taskName": "reservas",
        "Parking:taskName": "reservas",
        "Calendar:taskName": "eventos",
        "SharedCalendar:taskName": "eventos",
        "Contact:taskName": "contatos e grupos",
        "Gantt:taskName": "tarefas",
        "Keepass:taskName": "senhas",
        AddMembers: "Adicionar membros",
        AddMembersTagLine: "Comece a digitar o endereço de e-mail da pessoa que deseja adicionar",
        EmailAddress: "Digite o endereço de e-mail",
        SaveAlert: "Suas modificações foram salvas com sucesso!",
        CancelTagLine: "Se você prosseguir agora, todas as alterações que você fez serão descartadas. Tem certeza de que deseja continuar?",
        Continue: "Continuar",
        Return: "Retornar",
        "Permissions:FreeRedirect": "Você está usando uma versão gratuita de {appTitle}.",
        "Permissions:PremiumRedirect": "Você está usando uma versão premium de {appTitle}.",
        "Sync:PremiumRedirect": "Você está usando uma versão premium de {appTitle}. Clique <a href='https://admin.teams-pro.com/services' target='_blank'>aqui 🚀</a> para atualizar para Platinum e gerenciar sincronizações!",
        "Sync:FreeRedirect": "Você está usando uma versão gratuita de {appTitle}. Clique <a href='https://admin.teams-pro.com/services' target='_blank'>aqui 🚀</a> para atualizar para Platinum e gerenciar sincronizações!",
        NotAllowed: "Você não tem permissão para alterar as permissões.",
        NotAllowedNotif: "Você não tem permissão para alterar essa configuração.",
        CancelModifications: "Cancelar modificações",
        "Sync:Title": "Sincronizar calendários",
        "Sync:Manage": "Gerenciar",
        "Sync:Synchronize": "Sincronizar",
        "Sync:Add": "Adicionar um calendário",
        "Sync:SyncCalendarTitle": "Sincronizar calendários com meu ",
        "Sync:ShareTitle": "Compartilhar meu ",
        "Sync:CalendarName": "Nome do calendário",
        "Sync:ICSLink": "Link .ICS do calendário",
        "Sync:PublicAddress": "Endereço público formato iCal",
        "Sync:TableName": "Nome",
        "Sync:TableColor": "Cor",
        "Sync:TableShow": "Compartilhar",
        "Sync:TableState": "Estado",
        "Sync:SynchronizeLine": "Para gerenciar todos os seus calendários em um só lugar, sincronize seu calendário.",
        "Sync:SyncCalendarTooltip": "Você pode sincronizar no máximo 3 calendários. Seu calendário será sincronizado a cada 15 minutos.",
        "Sync:SyncTitle": "Adicionar calendário externo",
        AddAlert: "Os membros foram adicionados com sucesso",
        "Sync:ICSInput": "Digite uma URL",
        "Sync:NameInput": "Digite um nome",
        "Sync:TableAction": "Ação",
        "Sync:ManageTooltip": "Você não tem nenhum calendário sincronizado",
        AddressTitle: "Endereço",
        AddAddress: "Digite aqui para pesquisar um endereço",
        Participants: "Participantes",
        NoParticipants: "Nenhum resultado encontrado",
        "Sync:TabTooltip": "Você precisa salvar/cancelar suas alterações",
        "Sync:DeleteCalendar": "Excluir calendário",
        "Sync:DeleteTagLine": "Se você prosseguir agora, seu calendário será excluído da sua visualização. Para vê-lo novamente, você precisará sincronizá-lo novamente.",
        "Sync:NotAllowToShare": "Os proprietários do calendário não permitem que você compartilhe seus calendários com outras pessoas",
        "Sync:ConnectionIssue": "Problema de conexão com o calendário detectado. Verifique a existência do calendário e o status da conexão para uma sincronização sem problemas.",
        "Permissions:CreatorRole": "Proprietário deste calendário",
        Synchronized: "Sincronizado",
        Unsynchronized: "Não sincronizado",
        "Sync:UnsynchronizedTooltip": "Problema de conexão com o calendário detectado. Verifique a existência do calendário e o status da conexão para uma sincronização sem problemas.",
        "Sync:ShareInfo": "Compartilhe este calendário com os membros em ",
        AddParticipantsButton: "Adicionar participantes",
        "Sync:getErrorMessage": "Ocorreu um erro com seu link ICS no calendário: ",
        "Permissions:ManageRoleTitle": "Confirmação de Alteração de Função",
        "Permissions:ManageRoleTagLine": "Você está prestes a mudar a função de um membro em sua guia. Ao prosseguir, você concederá direitos que não poderá revogar de um membro. Tem certeza de que deseja continuar?",
        NotifDialogHeader: "Configurações de notificação do Teams",
        RedirectToAppSource: "<p style='text-align: center;'>Estamos levando você ao Microsoft Marketplace. Se você não for redirecionado, <a target='_blank' href='{link}'>clique aqui</a>.</p>",
        WhatsNewsOn: "O que há de novo?",
        NewsSoon: "Notícias em breve!",
        NeedConsentTitle: "Precisa de consentimento",
        NeedConsent: "Para realizar esta ação, precisaremos de consentimento. Se você confirmar, será redirecionado para a página de consentimento.",
        NoAddress: "Nenhum resultado encontrado no Google Maps",
        YourInfo: "Suas informações",
        ChooseTime: "Escolher data e hora",
        IC_SuperAdminTitle: "Atenção",
        IC_SuperAdminMessage: "Suas informações de pagamento estão prestes a expirar, atualize-as para evitar interrupções no serviço.",
        IC_SuperAdminCTA: "Ir para o Centro Administrativo",
        IC_AdminTitle: "Atenção",
        IC_AdminMessage: "O método de pagamento da sua licença associada está prestes a expirar.",
        IC_AdminCTA: "Clique aqui para informar seu administrador",
        IC_NotifTitle: "Atenção solicitada",
        IC_NotifMessage: "Acesse {appName} para atualizações importantes",
        IC_SuccesNotifTitle: "Sucesso",
        IC_SuccesNotifMessage: "A notificação foi enviada com sucesso para o seu Super Admin.",
        PreventUnlockPremiumFeaturesOnMobile: "Visite o Centro de Administração para atualizar para o Contacts Pro.",
        GoToAdminCenter: "Ir para o Centro de Administração",
        PremiumMessage1: "Você está usando uma versão premium do {appTitle}.",
        ClickHere: "Clique aqui 🚀",
        PremiumMessage2: "para atualizar para Platinum e gerenciar permissões de forma mais eficaz!",
        DiscardMessageTitle: "Alterações não guardadas",
        DiscardChanges: "Eliminar",
        ContinueEditing: "Continuar a editar",
        DiscardMessage: "Pretende eliminar as edições ou continuar a editar?",
        VisitLink: "Visitar o link",
        SelectCustomize: "Para personalizar as permissões, selecione 'Personalizar permissões' acima",
        SaveBeforeSearch: "Salve suas alterações antes de pesquisar",
        AccessMaster: "Este usuário tem acesso à senha mestra",
        "SuperAdmin:NoResult": "Não há resultados para",
        FeaturesNowAvailable: "Desfrute de todos os seus novos recursos agora!",
        KnowAboutFeatures: "Para saber mais sobre os recursos disponíveis para você, clique <a target='_blank' href='{link}'>aqui</a>!",
        Go: "Vamos lá!",
        NeedToBuyLicences: "Você precisa comprar licenças",
        HaveLicencesButNotEnough: "Você já tem algumas licenças, mas todas estão atribuídas. Você precisa comprar mais licenças para atribuir uma a si mesmo.",
        BuyMoreLicence: "Comprar mais licenças",
        GeneralPermissionsSettings: "Configurações gerais de permissões",
        CustomizedPermissionsSettings: "Configurações personalizadas de permissões",
        About: "Sobre",
        Version: "Versão",
        UniqueID: "Identificador único",
        Copied: "Copiado!",
        Copy: "Copiar",
        CreatedBy: "Criado por",
        SuggestFeature: "Sugerir uma Funcionalidade",
        HelpCenter: "Centro de Ajuda",
        "MoreApps": "Mais aplicativos",
    },
};
