import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppConfigurationSelector } from "../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import { Text, Tooltip } from "@fluentui/react-northstar";
import { setUserPerm, usePermissionsSelector } from "../../redux/reducers/Permissions/PermissionsReducer";
import { CompareModule } from "../../modules/Compare.module";
import { useDispatch } from "react-redux";
import { patchUserCalendarSync } from "../../apis/Permissions/PermissionsApi";
import { translations } from "../../translations";
import { SyncMenu } from "../../interfaces/Sync";
import { v4 as uuidv4 } from "uuid";
import { openModal, useModalSelector } from "../../redux/reducers/ModalReducer/ModalReducer";
import { Modal } from "../../interfaces/Modal";
import { useConfigurationSelector } from "../../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { GetCalendarSyncByIcsUrl } from "../../apis/apiCalendarSync";
import { Subject, concatMap } from "rxjs";
import { useMsTeamsSelector } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { setErrorMessage } from "../../redux/reducers/ErrorReducer/ErrorReducer";
import { useTranslate } from "../../hook/useTranslate";
import { usePremiumSelector } from "../../redux/reducers/PremiumReducer/PremiumReducer";
const subject$ = new Subject();
const localItemName = (id) => {
    return `not-selected-calendar-${id}`;
};
export const useSyncLogic = () => {
    const t = useTranslate(translations);
    const { appPathName, appId, isResourceMode, appName, app } = useAppConfigurationSelector("appPathName", "appId", "isResourceMode", "appName", "app");
    const { userPerm } = usePermissionsSelector("userPerm");
    const { isPlatinum, isPremium } = usePremiumSelector("isPlatinum", "isPremium");
    const { tenantId, userMail, userId } = useMsTeamsSelector("tenantId", "userMail", "userId");
    const { data } = useConfigurationSelector("data");
    const { isOpen } = useModalSelector("isOpen");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [menuItems, setMenuItems] = useState([]);
    const [deleteIndex, setDeleteIndex] = useState(undefined);
    const [synchronized, setSynchronized] = useState({});
    const synchronizedRef = useRef({});
    const emptySynchro = {
        id: uuidv4(),
        name: "",
        url: "",
        creator: userMail,
        color: "#6264a7",
        sharing: false,
    };
    const [bannerState, setBannerState] = useState({
        isSuccessBannerVisible: false,
    });
    const [state, setState] = useState({
        mySyncCalendar: userPerm.synchros.length !== 0 ? userPerm.synchros : [emptySynchro],
    });
    const initialState = useRef({
        mySyncCalendar: userPerm.synchros.length !== 0 ? userPerm.synchros : [emptySynchro],
    });
    useEffect(() => {
        const sub = subject$
            .pipe(concatMap(async (synchro) => {
            return await manageSynchronized(synchro);
        }))
            .subscribe(() => {
            setSynchronized(synchronizedRef.current);
        });
        return () => {
            sub.unsubscribe();
        };
    }, []);
    const manageSynchronized = async (synchro) => {
        try {
            await GetCalendarSyncByIcsUrl(synchro.url);
            synchronizedRef.current = { ...synchronizedRef.current, [synchro.id]: true };
        }
        catch (err) {
            const error = {
                id: uuidv4(),
                message: t("Sync:getErrorMessage") + synchro.name,
                error: err,
            };
            dispatch(setErrorMessage(error));
            synchronizedRef.current = { ...synchronizedRef.current, [synchro.id]: false };
        }
    };
    useEffect(() => {
        userPerm.synchros.forEach(async (s) => {
            subject$.next(s);
        });
    }, [userPerm.synchros]);
    const compareState = useMemo(() => {
        return CompareModule.areObjectsEqual(state, initialState.current);
    }, [state, initialState.current]);
    const showManageTooltip = (manageTab) => {
        if (manageTab && userPerm.synchros.length === 0)
            return t("Sync:ManageTooltip");
        if (!compareState)
            return t("Sync:TabTooltip");
    };
    useEffect(() => {
        const items = [
            {
                key: SyncMenu.synchronize,
                content: (_jsx(Tooltip, { content: showManageTooltip(false), styles: { pointerEvents: "auto" }, children: _jsx(Text, { children: t("Sync:Synchronize") }) })),
                index: 0,
                disabled: !compareState,
            },
        ];
        if (!isResourceMode) {
            items.push({
                key: SyncMenu.manage,
                content: (_jsx(Tooltip, { content: showManageTooltip(true), styles: { pointerEvents: "auto" }, children: _jsx(Text, { content: t("Sync:Manage") }) })),
                index: 1,
                disabled: isPlatinum === false || userPerm.synchros.length === 0 ? true : false || !compareState,
            });
        }
        setMenuItems(items);
    }, [userPerm, compareState]);
    const returnToApp = () => {
        if (!appPathName)
            return;
        navigate(`/${appPathName}/` + appId);
    };
    const handleInputOnChange = (index, props, data) => {
        let updatedMySyncCalendar = [...state.mySyncCalendar];
        if (index >= 0 && index < updatedMySyncCalendar.length) {
            updatedMySyncCalendar[index] = {
                ...updatedMySyncCalendar[index],
                [props]: data?.value ?? "",
            };
        }
        else {
            updatedMySyncCalendar.push({
                id: uuidv4(),
                name: props === "name" ? data?.value : "",
                url: props === "url" ? data?.value : "",
                creator: userMail,
                color: "#6264a7",
                sharing: false,
            });
        }
        setState((prev) => ({
            ...prev,
            mySyncCalendar: updatedMySyncCalendar,
        }));
    };
    const addCalendar = () => {
        let updatedMySyncCalendar = [...state.mySyncCalendar];
        updatedMySyncCalendar.push({
            id: uuidv4(),
            name: "",
            url: "",
            creator: userMail,
            color: "#6264a7",
            sharing: false,
        });
        setState((prev) => ({
            ...prev,
            mySyncCalendar: updatedMySyncCalendar,
        }));
    };
    const handleColorOnChange = (index, color) => {
        if (index >= 0 && index < state.mySyncCalendar.length) {
            const updatedMySyncCalendar = [...state.mySyncCalendar];
            updatedMySyncCalendar[index] = {
                ...updatedMySyncCalendar[index],
                color: color,
            };
            setState((prev) => ({
                ...prev,
                mySyncCalendar: updatedMySyncCalendar,
            }));
        }
    };
    const deleteSharedCalendar = (index) => {
        const updatedMySyncCalendar = [...state.mySyncCalendar];
        updatedMySyncCalendar.splice(index, 1);
        setState((prev) => ({
            ...prev,
            mySyncCalendar: updatedMySyncCalendar,
        }));
        initialState.current = {
            ...state,
            mySyncCalendar: updatedMySyncCalendar,
        };
        patchUserCalendarSync(appId, userId, updatedMySyncCalendar);
        dispatch(setUserPerm({ ...userPerm, synchros: updatedMySyncCalendar }));
    };
    const setEmptySynchro = () => {
        if (state.mySyncCalendar.length === 0) {
            setState((prev) => ({
                ...prev,
                mySyncCalendar: [emptySynchro],
            }));
            initialState.current = {
                ...state,
                mySyncCalendar: [emptySynchro],
            };
        }
    };
    const showManageCheckboxTooltip = () => {
        if (userPerm.perm.allowShareSynchro !== true)
            return t("Sync:NotAllowToShare");
    };
    const showUnsynchronizedTooltip = (isSynchronized) => {
        if (isSynchronized === false)
            return t("Sync:UnsynchronizedTooltip");
    };
    const handleShareCheckbox = (index) => {
        const updatedMySyncCalendar = [...state.mySyncCalendar];
        const updatedCalendar = { ...updatedMySyncCalendar[index] };
        updatedCalendar.sharing = !updatedCalendar.sharing;
        updatedMySyncCalendar[index] = updatedCalendar;
        setState((prevState) => ({
            ...prevState,
            mySyncCalendar: updatedMySyncCalendar,
        }));
    };
    const checkEmpty = () => {
        const item = state.mySyncCalendar[state.mySyncCalendar.length - 1];
        if (!item)
            return true;
        if (!item.name || !item.url)
            return true;
        if (!item.url.startsWith("https://") || !item.url.endsWith(".ics"))
            return true;
        return false;
    };
    const checkAllEmpty = () => {
        for (let i = 0; i < state.mySyncCalendar.length; i++) {
            const item = state.mySyncCalendar[i];
            if (item) {
                if (!item.name || !item.url)
                    return true;
                if (!item.url.startsWith("https://") || !item.url.endsWith(".ics"))
                    return true;
            }
        }
        return false;
    };
    const handleSave = async () => {
        setBannerState((prevState) => ({
            ...prevState,
            isSuccessBannerVisible: true,
        }));
        setTimeout(() => {
            setBannerState((prevState) => ({
                ...prevState,
                isSuccessBannerVisible: false,
            }));
        }, 5000);
        const updatedSynchros = [...state.mySyncCalendar];
        initialState.current = {
            ...state,
            mySyncCalendar: updatedSynchros,
        };
        if (updatedSynchros !== initialState.mySyncCalendar) {
            patchUserCalendarSync(appId, userId, updatedSynchros);
            dispatch(setUserPerm({ ...userPerm, synchros: updatedSynchros }));
        }
    };
    const handleCancelConfirmed = async () => {
        setState(initialState.current);
    };
    const handleDeleteConfirmed = async () => {
        const storedDataString = localStorage.getItem(localItemName(appId));
        const storedData = storedDataString ? JSON.parse(storedDataString) : {};
        const newData = Object.keys(storedData).reduce((acc, key) => {
            if (key !== state.mySyncCalendar[deleteIndex].id) {
                acc[key] = storedData[key];
            }
            return acc;
        }, {});
        localStorage.setItem(localItemName(appId), JSON.stringify(newData));
        deleteSharedCalendar(deleteIndex);
    };
    const toggleCancelDialog = () => {
        if (!isOpen)
            dispatch(openModal(Modal.PERMISSIONS_CANCEL_UNSAVE_CHANGE));
    };
    const toggleDeleteDialog = () => {
        if (!isOpen)
            dispatch(openModal(Modal.PERMISSIONS_DELETE_CALENDAR_SYNC));
    };
    return {
        state,
        initialState,
        compareState,
        bannerState,
        menuItems,
        isOpen,
        userPerm,
        appPathName,
        appId,
        tenantId,
        returnToApp,
        handleInputOnChange,
        handleSave,
        deleteSharedCalendar,
        checkEmpty,
        showManageTooltip,
        handleShareCheckbox,
        handleColorOnChange,
        handleCancelConfirmed,
        toggleCancelDialog,
        toggleDeleteDialog,
        setDeleteIndex,
        handleDeleteConfirmed,
        showManageCheckboxTooltip,
        showUnsynchronizedTooltip,
        checkAllEmpty,
        addCalendar,
        synchronized,
        setEmptySynchro,
        appName,
        app,
        isPlatinum,
        isPremium,
    };
};
