import { convertLocalDateToTimezone } from "../calendar/Calendar.utils";
import { GraphService, Modal, openModal, openPremiumDialog, useAppConfigurationSelector, useConfigurationSelector, useModalSelector, useMsTeamsSelector, useNotificationsSelector, usePermissionsSelector, usePremiumSelector, useTranslate, userHasRightsToEdit, } from "front";
import { IEventSelection, IOptionRecurrence } from "../../interfaces/Enums";
import { useEffect, useState } from "react";
import { useCalendarCache } from "../../services/cacheService/calendarCache";
import moment from "moment";
import { clearTimezoneFromDates, datesAreValid, formatDateWithoutTimezone, updateEventRRule } from "./eventUtils";
import * as microsoftTeams from "@microsoft/teams-js";
import { useDispatch, useSelector } from "react-redux";
import { useNavBarSelector } from "../../redux/reducers/navbarReducer";
import { translations } from "../../translations";
import { useOptionsSelector } from "../../redux/reducers/optionsReducer";
import { months } from "../../const/Months";
export const PATH_TO_SHARE = "/calendar/action/compose";
export const ADD_EVENT = "addevent";
export const useLogic = (props) => {
    const { calendar, mutateCreateEvent, mutateUpdateEvent, mutateDeleteEvent, mutateDeleteOccurenceOfSerieEvent, mutateAddTags, resources } = useCalendarCache();
    const { userPerm } = usePermissionsSelector("userPerm");
    const { data } = useConfigurationSelector("data");
    const { nameApp } = useOptionsSelector("nameApp");
    const { isOpen } = useModalSelector("isOpen");
    const { calendarTimeZone } = useNavBarSelector("calendarTimeZone");
    const { update, delete: deleteNotif } = useNotificationsSelector("delete", "update");
    const { isPremium } = usePremiumSelector("isPremium");
    const { userMail, teamId, groupId, userId, channelId, tabInternalId, userName } = useMsTeamsSelector("teamId", "groupId", "userId", "userMail", "channelId", "tabInternalId", "userName");
    const t = useTranslate(translations);
    const appInsightInstance = useSelector((s) => s.infosApp.appInsightInstance);
    const dispatch = useDispatch();
    const { from } = useAppConfigurationSelector("from");
    const initialEvent = {
        allDay: false,
        calendarId: "",
        startDate: moment().toISOString(),
        endDate: moment().toISOString(),
        modeRecurrence: IOptionRecurrence.NotRepeat,
        isRecurrenceValid: true,
        timeZone: calendarTimeZone,
        title: "",
        color: undefined,
        description: undefined,
        address: {
            fullAddress: "",
            lat: 0,
            lng: 0,
        },
        tags: new Array(),
    };
    /**
     * Event view default state
     */
    const defaultState = {
        event: initialEvent,
        initialEvent: initialEvent,
        showDiscardChangesDialog: false,
        isSavingEvent: false,
        isDeletingEvent: false,
        deleteChoice: undefined,
        showDeleteDialog: false,
        showAboutDialog: false,
        isEventOccurenceFromSerie: false,
        showWarningUpdateRecurrenceDialog: false,
        errorMessage: "",
        conflictEvent: null,
        conflictDatesString: "",
        optionsEvent: [],
        hasClickOnSuggestedTime: false
    };
    const [userCanEditEvent, setUserCanEditEvent] = useState(false);
    const [state, setState] = useState(defaultState);
    useEffect(() => {
        if (!userPerm["id"])
            return;
        const isAllow = userHasRightsToEdit(userPerm);
        setUserCanEditEvent(isAllow);
    }, [userPerm]);
    useEffect(function initialize() {
        if (!props.event?.event)
            return;
        let formattedEvent = clearTimezoneFromDates(props.event.event);
        formattedEvent = updateEventRRule(formattedEvent, props.event.event.modeRecurrence);
        let initialEvent = formattedEvent;
        if (props.event.isAlreadyUpdated) {
            initialEvent = props.event.events.find((e) => e.id === formattedEvent.id);
            if (!initialEvent)
                return;
            initialEvent = clearTimezoneFromDates(initialEvent);
            initialEvent = updateEventRRule(initialEvent, initialEvent.modeRecurrence);
        }
        const isEventOccurenceFromSerie = !!formattedEvent.groupId && formattedEvent.id !== formattedEvent.groupId;
        formattedEvent.color = formattedEvent.color ?? "#6264a7";
        formattedEvent.emoji = formattedEvent.emoji ?? undefined;
        setState({
            ...defaultState,
            isEventOccurenceFromSerie,
            event: formattedEvent,
            initialEvent: initialEvent,
        });
    }, [props.event]);
    useEffect(() => {
        if (!state.hasClickOnSuggestedTime)
            return;
        const timer = setTimeout(() => {
            setState({ ...state, hasClickOnSuggestedTime: false });
        }, 3000);
        return () => clearTimeout(timer);
    }, [state.hasClickOnSuggestedTime]);
    const updateAddress = (address) => {
        setState((prev) => {
            return { ...prev, event: { ...prev.event, address: address } };
        });
    };
    /**
     * Add a new event in the database
     * @param event new event
     */
    const addNewEvent = async (event, allowDoubleBooking) => {
        if (event.modeRecurrence !== IOptionRecurrence.NotRepeat && !event.groupId)
            event.groupId = event.id;
        if (!calendar?.id || !props.event)
            return;
        mutateCreateEvent({ calendarId: calendar.id, event: event, queryKey: props.event.queryKey, allowDoubleBooking, calendarTimeZone }, {
            onSuccess: () => {
                setState({ ...defaultState });
                props.onClose();
            },
            onError: (err) => {
                if (err.error === "An event is already booked at this moment.") {
                    handleError(err.conflictResult);
                }
                else {
                    setState({ ...state, isSavingEvent: false });
                }
            },
        });
    };
    /**
     * Update an existing event
     * @param event event to update
     */
    const updateEvent = async (event, allowDoubleBooking) => {
        if (event.modeRecurrence !== IOptionRecurrence.NotRepeat && !event.groupId)
            event.groupId = event.id;
        if (!calendar?.id || !props.event)
            return;
        mutateUpdateEvent({ calendarId: calendar.id, event: event, queryKey: props.event.queryKey, allowDoubleBooking, calendarTimeZone }, {
            onSuccess: () => {
                setState({ ...defaultState });
                props.onClose();
            },
            onError: (err) => {
                if (err.error === "An event is already booked at this moment.") {
                    handleError(err.conflictResult);
                }
                else {
                    setState({ ...state, isSavingEvent: false });
                }
            },
        });
    };
    const handleError = (conflictResult) => {
        if (conflictResult.event.rrule) {
            const formattedDates = formatConflictDates(conflictResult.conflictDates, conflictResult.event.timeZone);
            let formattedDatesString = "";
            formattedDates.forEach(date => formattedDatesString = formattedDatesString + date + "<br />");
            setState({ ...state, isSavingEvent: false, conflictEvent: JSON.parse(JSON.stringify(conflictResult.event)), conflictDatesString: formattedDatesString, optionsEvent: null });
            if (!isOpen)
                dispatch(openModal(Modal.EVENT_CONFLICT));
            return;
        }
        const name = from;
        const eventLabel = name === "Parking" ? t("AnotherParkingPlace") : name === "Booking" ? t("AnotherRoom") : "";
        let errorMessage = t("AlreadyBooked", { event: eventLabel });
        setState({ ...state, isSavingEvent: false, errorMessage, conflictEvent: JSON.parse(JSON.stringify(conflictResult.event)), optionsEvent: JSON.parse(JSON.stringify(conflictResult.options)) });
    };
    const handleTimeClick = (index) => {
        const selectedEvent = state.optionsEvent[index];
        let momentStart = moment(selectedEvent.startDate).tz(selectedEvent.timeZone);
        let momentEnd = moment(selectedEvent.endDate).tz(selectedEvent.timeZone);
        const start = formatDateWithoutTimezone(momentStart);
        const end = formatDateWithoutTimezone(momentEnd);
        setState(prevState => ({
            ...prevState,
            event: {
                ...prevState.event,
                startDate: start,
                endDate: end,
                allDay: false
            },
            hasClickOnSuggestedTime: true
        }));
    };
    const formatConflictDates = (dates, timezone) => {
        let lastDateMonth = null;
        const datesString = dates.map(date => {
            let startDate = new Date(date.Item1);
            startDate = convertLocalDateToTimezone(startDate, timezone).toDate();
            let endDate = new Date(date.Item2);
            endDate = convertLocalDateToTimezone(endDate, timezone).toDate();
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const dayName = t(days[startDate.getDay()]);
            const currentMonth = startDate.getMonth();
            let monthTitle = "";
            if (lastDateMonth === null || currentMonth !== lastDateMonth) {
                if (lastDateMonth !== null)
                    monthTitle = `<br />`;
                monthTitle = monthTitle + `<b>${t(months[currentMonth])} ${startDate.getFullYear()}</b>` + `<br />`;
                lastDateMonth = currentMonth;
            }
            const startDateString = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
            const startTimeString = `${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;
            const endTimeString = `${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`;
            const dateString = monthTitle + `${startDateString} (${dayName}) ${t("AlreadyBookedAt")} ${startTimeString} - ${endTimeString}`;
            monthTitle = "";
            return dateString;
        });
        return datesString;
    };
    const deleteOccurenceOfSerieEvent = async (event) => {
        mutateDeleteOccurenceOfSerieEvent({ calendarId: calendar.id, event: event, queryKey: props.event.queryKey }, {
            onSuccess: () => {
                setState({ ...defaultState });
                props.onClose();
            },
        });
    };
    /**
     * On delete event
     */
    const deleteEvent = async () => {
        const deleteOnlyOccurence = state.deleteChoice !== undefined ? state.deleteChoice === IEventSelection.Occurrence : !state.event.groupId;
        setState((prev) => ({ ...prev, showDeleteDialog: false, isDeletingEvent: true }));
        if (!calendar?.id || !props.event || !props.event.event)
            return;
        mutateDeleteEvent({ calendarId: calendar.id, event: props.event.event, deleteOnlyOccurence: deleteOnlyOccurence, queryKey: props.event.queryKey }, {
            onSuccess: () => {
                setState({ ...defaultState });
                const participantsNotif = state.event.participants?.filter((p) => p.id != userId);
                if (participantsNotif?.length > 0 && deleteNotif) {
                    const webUrl = encodeURIComponent(`${window.location.origin}/calendars/${calendar.id ?? ""}`);
                    GraphService.sendNotification(participantsNotif, groupId, channelId, t("deleteEventTitle"), state.event.title, data.manifestId, calendar.id ?? "", nameApp, webUrl);
                }
                props.onClose();
            },
        });
    };
    /**
     * On confirm delete from dialog
     */
    const onConfirmDelete = async () => await deleteEvent();
    const onSeeConflicts = () => {
        if (!isOpen)
            dispatch(openModal(Modal.EVENT_CONFLICT));
    };
    /**
     * On save event
     */
    const onSaveEvent = async (allowDoubleBooking = false) => {
        // Show warning message if updating serie with custom occurences
        if (!state.showWarningUpdateRecurrenceDialog && !!state.event.id && state.event.id === state.event.groupId) {
            const event = state.event;
            const initialEvent = state.initialEvent;
            if (event.startDate !== initialEvent.startDate || event.endDate !== initialEvent.endDate || event.modeRecurrence !== initialEvent.modeRecurrence) {
                const customOccurences = props.event?.events.filter((e) => e.id !== e.groupId && e.groupId === state.event.id);
                if ((customOccurences && customOccurences.length > 0) || event.excludeDate?.length) {
                    setState((prev) => ({ ...prev, showWarningUpdateRecurrenceDialog: true }));
                    return;
                }
            }
        }
        setState((prev) => ({ ...prev, isSavingEvent: true, showWarningUpdateRecurrenceDialog: false, optionsEvent: null }));
        let newEvent = { ...state.event };
        if (!newEvent.isRecurrenceValid)
            return;
        // Show premium dialog in case event is recurrent and user not premium
        if (newEvent.modeRecurrence !== IOptionRecurrence.NotRepeat && !isPremium) {
            dispatch(openPremiumDialog(appInsightInstance));
            setState((prev) => ({ ...prev, isSavingEvent: false }));
            return;
        }
        if (!!newEvent.rrule && newEvent.modeRecurrence === IOptionRecurrence.Custom)
            newEvent.rrule.dtstart = formatDateWithoutTimezone(newEvent.startDate);
        // Clear custom occurences of recurrence before becoming a simple event
        if (!!state.initialEvent.id &&
            state.initialEvent.id === state.initialEvent.groupId &&
            state.initialEvent.modeRecurrence !== IOptionRecurrence.NotRepeat &&
            newEvent.modeRecurrence === IOptionRecurrence.NotRepeat) {
            await deleteOccurenceOfSerieEvent({ ...newEvent, excludeDate: undefined });
            newEvent.groupId = undefined;
            newEvent.excludeDate = undefined;
        }
        // Current dates don't have timezone, we apply event timezone
        newEvent.startDate = convertLocalDateToTimezone(newEvent.startDate, newEvent.timeZone).toISOString();
        newEvent.endDate = convertLocalDateToTimezone(newEvent.endDate, newEvent.timeZone).toISOString();
        if (!!newEvent.rrule && !newEvent.allDay) {
            if (newEvent.rrule.dtstart) {
                var rRuleDtStart = moment(convertLocalDateToTimezone(newEvent.rrule.dtstart, newEvent.timeZone).toISOString());
                newEvent.rrule.dtstart = rRuleDtStart.toISOString();
            }
            if (newEvent.rrule.until)
                newEvent.rrule.until = convertLocalDateToTimezone(newEvent.rrule.until, newEvent.timeZone).toISOString();
        }
        if (!datesAreValid(newEvent))
            return;
        if (!!newEvent.groupId && newEvent.id === newEvent.groupId && state.showWarningUpdateRecurrenceDialog) {
            const customOccurences = props.event?.events.filter((e) => e.id !== e.groupId && e.groupId === state.event.id);
            if (customOccurences && customOccurences.length)
                newEvent.groupId && (await deleteOccurenceOfSerieEvent(newEvent));
            newEvent.excludeDate = undefined;
        }
        if (newEvent.rrule && newEvent.rrule?.until)
            newEvent.rrule.until = moment(newEvent.rrule.until).format("YYYY/MM/DD");
        if (state.conflictEvent !== null && state.conflictEvent.excludeDate?.length > 0)
            newEvent.excludeDate = state.conflictEvent.excludeDate;
        if (!!newEvent.id) {
            newEvent.updated = moment().toDate();
            newEvent.updatedBy = userMail;
            await updateEvent(newEvent, allowDoubleBooking);
        }
        else {
            newEvent.updated = newEvent.created;
            newEvent.updatedBy = null;
            const index = props.event.events.findIndex((e) => e.groupId === newEvent.groupId && e.id === newEvent.groupId);
            if (index !== -1) {
                const excludeDate = props.event.event.allDay
                    ? formatDateWithoutTimezone(props.event.event.startDate)
                    : moment.utc(props.event.event.startDate).set("milliseconds", 0).format("YYYY/MM/DD HH:mm:ss");
                if (!props.event.events[index].excludeDate)
                    props.event.events[index].excludeDate = new Array(excludeDate);
                props.event.events[index].excludeDate.push(excludeDate);
                await updateEvent(props.event.events[index], true); // trigger when editing an occurrency of a serie
            }
            await addNewEvent(newEvent, allowDoubleBooking);
        }
        const tagsToAdd = [];
        newEvent.tags?.forEach((t) => {
            if (calendar?.tags?.includes(t))
                return;
            tagsToAdd.push(t);
        });
        if (tagsToAdd.length > 0) {
            mutateAddTags({ calendarId: calendar?.id, tags: tagsToAdd });
        }
        const participantsNotif = newEvent.participants?.filter((p) => p.id != userId);
        if (participantsNotif?.length > 0 && update) {
            let ressourceName = "";
            if (newEvent.resourceId) {
                ressourceName = resources.find((r) => r.id == newEvent.resourceId).title;
            }
            const messageTitle = !newEvent.id
                ? t("addEventTitle", { userName: userName, ressourceName }) + " : " + newEvent.title
                : t("updateEventTitle", { userName: userName, ressourceName }) + " : " + newEvent.title;
            const messageDesc = newEvent.allDay ? t("AllDay") : moment(newEvent.startDate).format("LLLL");
            const webUrl = encodeURIComponent(`${window.location.origin}/calendars/${calendar.id ?? ""}`);
            GraphService.sendNotification(participantsNotif, groupId, channelId, messageTitle, messageDesc, data.manifestId, calendar.id ?? "", nameApp, webUrl);
        }
    };
    /**
     * Check if event has been updated
     */
    const isEventHasBeenUpdated = () => {
        if (!state.initialEvent)
            return true;
        let initialEvent = JSON.stringify(state.initialEvent);
        let event = JSON.stringify(state.event);
        return initialEvent !== event;
    };
    /**
     * When closing the window
     * @param hasConfirmed user has confirmed to close
     */
    const onClose = (hasConfirmed) => () => {
        if (!hasConfirmed && isEventHasBeenUpdated()) {
            setState((prev) => ({ ...prev, showDiscardChangesDialog: true }));
            return;
        }
        setState({ ...defaultState });
        props.onClose();
    };
    /**
     * Close discard changes dialog
     */
    const closeDiscardChangesDialog = () => setState((prev) => ({ ...prev, showDiscardChangesDialog: false }));
    /**
     * Close warning update recurrence dialog
     */
    const closeWarningUpdateRecurrenceDialog = () => setState((prev) => ({ ...prev, showWarningUpdateRecurrenceDialog: false }));
    /**
     * Is save button disabled
     */
    const isSaveButtonDisabled = () => {
        return (!isEventHasBeenUpdated() ||
            !state.event.title ||
            !state.event.isRecurrenceValid ||
            state.isDeletingEvent ||
            state.isSavingEvent ||
            !datesAreValid(state.event) ||
            (!isPremium && state.event.modeRecurrence !== IOptionRecurrence.NotRepeat));
    };
    /**
     * Delete event occurence of serie
     * @param choice delete choice
     */
    const deleteEventOccurenceOrSerie = (choice) => () => {
        setState((prev) => ({ ...prev, showDeleteDialog: true, deleteChoice: choice }));
    };
    const onShareEvent = () => {
        if (!isPremium) {
            dispatch(openPremiumDialog(appInsightInstance));
            return;
        }
        let queryObject = {
            path: PATH_TO_SHARE,
            rru: ADD_EVENT,
        };
        if (!state.event.allDay)
            queryObject.enddt = state.event.endDate;
        queryObject.startdt = state.event.startDate;
        queryObject.subject = state.event.title;
        queryObject.allday = state.event.allDay ? "true" : "false";
        const query = new URLSearchParams(queryObject);
        microsoftTeams.app.openLink("https://outlook.office.com/calendar/0/action/compose?" + query.toString());
    };
    /**
     * On update event
     */
    const onUpdateEvent = (event) => {
        setState((prev) => {
            return { ...prev, event };
        });
    };
    /**
     * Close delete modal
     */
    const closeDeleteModal = () => setState((prev) => ({ ...prev, showDeleteDialog: false }));
    /**
     * On click delete button
     * (if event is a serie, it's a menu button, so we do nothing)
     */
    const onClickDeleteButton = () => {
        return !state.isEventOccurenceFromSerie ? setState((prev) => ({ ...prev, showDeleteDialog: true })) : undefined;
    };
    /**
     * Show about dialog
     */
    const showAboutDialog = () => setState((prev) => ({ ...prev, showAboutDialog: true }));
    /**
     * Close about dialog
     */
    const closeAboutDialog = () => setState((prev) => ({ ...prev, showAboutDialog: false }));
    return {
        state,
        userCanEditEvent,
        addNewEvent,
        updateEvent,
        deleteEvent,
        onConfirmDelete,
        onSaveEvent,
        isEventHasBeenUpdated,
        onClose,
        closeDiscardChangesDialog,
        isSaveButtonDisabled,
        closeWarningUpdateRecurrenceDialog,
        deleteEventOccurenceOrSerie,
        onShareEvent,
        onUpdateEvent,
        closeDeleteModal,
        onClickDeleteButton,
        showAboutDialog,
        closeAboutDialog,
        updateAddress,
        isOpen,
        onSeeConflicts,
        handleTimeClick,
        setState
    };
};
