import * as microsoftTeams from "@microsoft/teams-js";
import { UserTeamRole } from "@microsoft/teams-js";
import { MSTEAMS_INIT, ThemeClass } from "./MicrosoftTeamsReducer.interfaces";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { teamsTheme } from "@fluentui/react-northstar";
import { getCurrentTheme, initializeFromTeams, initializeOutsideOfTeams } from "../../../services/MicrosoftTeamsService/MicrosoftTeamsService";
import { getSliceSelector } from "../../utils";
const initialState = {
    loaded: false,
    locale: "en",
    fullLocale: "en-US",
    themeClass: ThemeClass.Default,
    groupId: "",
    channelId: "",
    teamId: "",
    teamName: "",
    tenantId: "",
    channelName: "",
    userMail: "",
    userName: "",
    userId: "",
    hostClientType: "",
    isConfiguringApp: false,
    isDeletingApp: false,
    isOnMobile: false,
    isInPersonalApp: false,
    subEntityId: "",
    isInTaskModule: false,
    entityId: "",
    isInMeeting: false,
    meetingId: "",
    isInMeetingSidePanel: false,
    userRole: UserTeamRole.User,
    isTouchScreen: false,
    isTeamsIframe: true,
    isLightTheme: true,
    theme: teamsTheme,
    teamSiteDomain: "",
    teamSitePath: "",
    tabInternalId: "",
    tabName: "",
    newTeams: false,
    isPrivateChannel: false,
    sku: "",
};
const slice = createSlice({
    name: "msTeams",
    initialState,
    reducers: {
        setMsTeams: (state, action) => {
            Object.assign(state, action.payload);
        },
        setTheme(state, action) {
            let themeClass = ThemeClass.Default;
            switch (action.payload) {
                case "dark":
                    themeClass = ThemeClass.Dark;
                    break;
                case "contrast":
                    themeClass = ThemeClass.HighContrast;
                    break;
                default:
                    break;
            }
            document.body.id = themeClass;
            state.theme = getCurrentTheme(themeClass);
            state.themeClass = themeClass;
            state.isLightTheme = state.themeClass === ThemeClass.Default;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initializeMSTeams.fulfilled, (state, action) => {
            if (!action.payload)
                throw new Error("No Access token");
            Object.assign(state, action.payload);
        });
    },
});
export const initializeMSTeams = createAsyncThunk(MSTEAMS_INIT, async (data, { dispatch }) => {
    let state;
    if (data.isTeamsApp) {
        state = await initializeFromTeams(data.accessToken);
        microsoftTeams.app.registerOnThemeChangeHandler((theme) => dispatch(setTheme(theme)));
        const context = await microsoftTeams.app.getContext();
        dispatch(setTheme(context.app.theme ?? ""));
    }
    else {
        state = await initializeOutsideOfTeams(data.accessToken);
        dispatch(setTheme("light"));
    }
    if (!data.accessToken)
        return;
    if (data.isTeamsApp)
        microsoftTeams.app.notifySuccess();
    return state;
});
export const handleMouseDown = (e) => (dispatch) => {
    const isTouchScreen = e.sourceCapabilities?.firesTouchEvents;
    if (isTouchScreen === undefined)
        return;
    dispatch(setMsTeams({ isTouchScreen }));
};
export const handleTouchStart = () => (dispatch) => {
    dispatch(setMsTeams({ isTouchScreen: true }));
};
export const clearListeners = () => (dispatch) => {
    document.body.removeEventListener("mousedown", (e) => dispatch(handleMouseDown(e)));
    document.body.removeEventListener("touchstart", () => dispatch(handleTouchStart()));
};
export const setListeners = () => (dispatch) => {
    document.body.addEventListener("mousedown", (e) => dispatch(handleMouseDown(e)));
    document.body.addEventListener("touchstart", () => dispatch(handleTouchStart()));
};
export const { setMsTeams, setTheme } = slice.actions;
export const useMsTeamsSelector = getSliceSelector(slice);
export const msTeamsReducer = slice.reducer;
