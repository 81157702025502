import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { freeDisplayedResourcesLimit, labelsMaxWidth, timeZones, useLogic } from "./EventForm.logic";
import { useCalendarCache } from "../../../services/cacheService/calendarCache";
import { GoogleRenderComponent, Participant, RichTextEditor, SearchDropdown, highlightChildrenClass, useMsTeamsSelector, usePremiumSelector, useTranslate } from "front";
import { Accordion, AddIcon, Alert, Avatar, BookmarkIcon, Button, CalendarIcon, Checkbox, CloseIcon, Datepicker, Dropdown, EditIcon, EmojiIcon, ErrorIcon, ExclamationTriangleIcon, Flex, FormInput, HighlightIcon, IndentIcon, Input, LocationIcon, MeetingTimeIcon, Popup, Segment, SyncIcon, TagIcon, Text, TrashCanIcon, UndoIcon, } from "@fluentui/react-northstar";
import { translations } from "../../../translations";
import Picker, { SkinTones } from "emoji-picker-react";
import moment from "moment";
import { addFiveYears, addYearAndHalf, datesAreValid, formatToLocalHours, formatToLocalMinutes, getHours, getMinutes } from "../eventUtils";
import { CustomRecurrenceDialog } from "../../dialogs/CustomRecurrence/CustomRecurrenceDialog";
import { SketchPicker } from "react-color";
import { FilesComponent } from "../../files/FilesComponent";
import { useOptionsSelector } from "../../../redux/reducers/optionsReducer";
import { IOptionRecurrence } from "../../../interfaces/Enums";
/**
 * Event form component
 */
export const EventForm = (props) => {
    const t = useTranslate(translations);
    const logic = useLogic(props);
    const { calendar } = useCalendarCache();
    const { isOnMobile, themeClass, channelId } = useMsTeamsSelector("isOnMobile", "themeClass", "channelId");
    const { isPremium } = usePremiumSelector("isPremium");
    const { allowAddFile, allowDoubleBooking } = useOptionsSelector("allowAddFile", "allowDoubleBooking");
    /**
     * Render avatar
     */
    const renderAvatar = () => (_jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(Text, { content: t("CreatedBy") }), _jsx(Avatar, { name: props.event.createdBy?.toUpperCase().substring(0, props.event.createdBy.indexOf("@")) ?? "" })] }));
    /**
     * Render resource input
     */
    const renderResourceInput = () => {
        let displayedResources = props.resources.map((item) => {
            return { header: item.title, value: item.id, disabled: false };
        });
        displayedResources = !isPremium ? displayedResources.slice(0, freeDisplayedResourcesLimit) : displayedResources;
        return (_jsxs(Flex, { vAlign: "center", column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(BookmarkIcon, { outline: true, className: "Icon" }), _jsx(Text, { content: t("LabelResource") })] }) }), _jsx(SearchDropdown, { FUI: {
                        disabled: logic.state.readOnly || props.disabled,
                        fluid: true,
                        inverted: !isOnMobile,
                        items: displayedResources,
                        onChange: logic.onUpdateEventFromDropdownOrInput("resourceId"),
                        noResultsMessage: t("Tag:NoResult"),
                    }, options: {
                        defaultValue: logic.ressourceValue,
                        fill: true,
                        resetValue: true
                    } })] }));
    };
    /**
     * Render title input
     */
    const renderTitleInput = () => (_jsxs(Flex, { vAlign: "center", column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(EditIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: t("LabelTitle") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { vAlign: "center", children: _jsx(FormInput, { fluid: true, styles: { width: "100%", position: "relative" }, required: true, error: !props.event.title, errorMessage: !props.event.title, max: 100, inverted: !isOnMobile, value: props.event.title ?? undefined, disabled: logic.state.readOnly || props.disabled, placeholder: !props.event.title ? t("EmptyFillError") : t("AddTitle"), onChange: logic.onUpdateEventFromDropdownOrInput("title") }) }) })] }));
    const renderEmojiPicker = () => (_jsxs(Flex, { column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(EmojiIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: t("EmojiLabel") })] }) }), _jsx(Flex, { gap: "gap.small", vAlign: "center", children: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(Popup, { position: isOnMobile ? "above" : "after", align: "top", pointing: true, trapFocus: true, trigger: _jsx(Button, { iconOnly: true, onClick: () => logic.onCheckEmojiPickerAndShareButton(), content: _jsx(Text, { style: { margin: "auto", display: "flex" }, content: props.event.emoji ?? _jsx(EmojiIcon, { outline: true }) }), disabled: logic.state.readOnly || props.disabled }), content: isPremium ? (_jsx(Picker, { onEmojiClick: logic.onEmojiClick, autoFocusSearch: false, defaultSkinTone: SkinTones.MEDIUM_DARK })) : (_jsx(_Fragment, {})) }), !!props.event.emoji && _jsx(Button, { icon: _jsx(TrashCanIcon, {}), text: true, iconOnly: true, onClick: logic.onClearEmoji })] }) })] }));
    /**
     * Render start date input
     */
    const renderStartDateInput = () => (_jsxs(Flex, { vAlign: "center", column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(CalendarIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: t("LabelStartDate") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsxs(Flex, { column: isOnMobile, vAlign: "center", gap: "gap.small", children: [_jsx(Datepicker, { allowManualInput: false, inputOnly: true, styles: { width: "100%", padding: "0 !important" }, input: { fluid: true, inverted: !isOnMobile }, disabled: logic.state.readOnly || props.disabled, formatMonthDayYear: logic.formatDateToLocalDateFormat, selectedDate: moment(props.event.startDate).toDate(), onDateChange: logic.onUpdateEventFromDropdownOrInput("startDate") }), !props.event.allDay && (_jsxs(Flex, { fill: isOnMobile, gap: "gap.small", children: [renderHoursDropDown(props.event.startDate, logic.onUpdateStartTime), renderMinutesDropDown(props.event.startDate, logic.onUpdateStartTime)] }))] }) })] }));
    /**
     * Render end date input
     */
    const renderEndDateInput = () => (_jsxs(Flex, { vAlign: "center", column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(CalendarIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: t("LabelEndDate") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsxs(Flex, { column: isOnMobile, vAlign: "center", gap: "gap.small", children: [_jsx(Datepicker, { allowManualInput: false, inputOnly: true, styles: { width: "100%" }, input: { fluid: true, inverted: !isOnMobile }, disabled: logic.state.readOnly || props.disabled, formatMonthDayYear: logic.formatDateToLocalDateFormat, selectedDate: moment(props.event.endDate).toDate(), onDateChange: logic.onUpdateEventFromDropdownOrInput("endDate") }), !props.event.allDay && (_jsxs(Flex, { fill: isOnMobile, gap: "gap.small", children: [renderHoursDropDown(props.event.endDate, logic.onUpdateEndTime), renderMinutesDropDown(props.event.endDate, logic.onUpdateEndTime)] }))] }) })] }));
    /**
     * Render timezone dropdown
     */
    const renderTimeZoneDropdown = () => {
        // Need to force completely rerender item to refresh value
        return (props.show && (_jsxs(Flex, { column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(MeetingTimeIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: t("LabelSelectionTimeZone") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { vAlign: "center", children: _jsx(Dropdown, { styles: { width: "100%" }, search: true, fluid: true, inverted: true, disabled: logic.state.readOnly || props.disabled, value: props.event.timeZone, placeholder: props.event.timeZone, items: timeZones, onChange: logic.onUpdateEventFromDropdownOrInput("timeZone") }) }) })] })));
    };
    /**
     * Render all day toggle button
     */
    const renderAllDayToggle = () => (_jsx(Flex, { gap: "gap.small", children: _jsx(Checkbox, { styles: { marginLeft: "-5px !important" }, disabled: logic.state.readOnly || props.disabled, toggle: true, label: t("AllDay"), checked: props.event.allDay, onChange: logic.onUpdateEventFromCheckBox("allDay") }) }));
    /**
     * Render recurrence mode dropdown
     */
    const renderRecurrenceDropdown = () => {
        return (_jsxs(Flex, { column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(SyncIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: t("LabelRecurrence") })] }) }), _jsx(Flex.Item, { grow: true, children: !props.isEventOccurenceFromSerie ? (_jsx(Dropdown, { fluid: true, inverted: !isOnMobile, disabled: logic.state.readOnly || props.disabled, value: logic.getRecurrenceModeLabel(), items: logic.displayRecurrence, checkable: true, onChange: logic.onUpdateEventFromDropdownOrInput("modeRecurrence") })) : (_jsx(Text, { disabled: true, content: t("ViewOccurrenceOfSeries") })) })] }));
    };
    /**
     * Render recurrence warn message in case of invalid recurrence
     */
    const renderRecurenceWarnMessage = () => (_jsxs(Flex, { column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { minWidth: labelsMaxWidth }, children: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(ErrorIcon, { className: "Icon" }), _jsx(Text, { content: t("LabelWarning") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Alert, { danger: true, content: t("RecurrenceInfo") }) })] }));
    /**
     * Render dates warn message in case of invalid dates
     */
    const renderDatesWarnMessage = () => (_jsxs(Flex, { gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { minWidth: labelsMaxWidth }, children: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(ErrorIcon, { className: "Icon" }), _jsx(Text, { content: t("LabelError") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Alert, { danger: true, content: t("ErrorDate") }) })] }));
    /**
     * Render dates warn message in case of invalid dates
    */
    const renderRecurrenceMaxDateWarnMessage = () => (_jsxs(Flex, { gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { minWidth: labelsMaxWidth }, children: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(ExclamationTriangleIcon, { outline: true, className: "Icon" }), _jsx(Text, { content: t("LabelWarning") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Alert, { warning: true, content: t("WarningRecurrenceMaxDate") }) })] }));
    /**
     * Render description input
     */
    const renderDescriptionInput = () => {
        return (_jsxs(Flex, { column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { minWidth: labelsMaxWidth }, children: _jsxs(Flex, { gap: "gap.small", children: [_jsx(IndentIcon, { outline: true, styles: { marginTop: "4px" } }), _jsx(Text, { truncated: true, content: t("LabelDescription") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(RichTextEditor, { ref: logic.richTextEditorRef, maxHeight: 150, maxContent: 300, setValue: props.event.description ?? "", handleChange: logic.onUpdateEventFromValue("description") }) })] }));
    };
    /**
     * Render color input
     */
    const renderColorInput = () => {
        const styles = {
            color: {
                width: "36px",
                height: "14px",
                borderRadius: "2px",
                background: props.event.color ?? "#6264a7",
            },
            swatch: {
                position: "relative",
                padding: "5px",
                background: "#fff",
                borderRadius: "1px",
                boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                display: "inline-block",
                cursor: "pointer",
            },
            popover: {
                position: "static",
                zIndex: "2",
            },
            cover: {
                float: "right",
                marginRight: "5px",
                marginTop: "5px",
                cursor: "pointer",
            },
        };
        return (_jsxs(Flex, { column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { minWidth: labelsMaxWidth }, children: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(HighlightIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: t("Color") })] }) }), _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [!logic.isColorPickerVisible && (_jsx("div", { id: "color-picker-button", style: styles.swatch, onClick: logic.displayColorPicker, children: _jsx("div", { id: "color-picker-buttoncolor", style: styles.color }) })), logic.isColorPickerVisible && (_jsxs("div", { style: styles.popover, id: "popover-color-picker", children: [_jsx("div", { style: styles.cover, onClick: logic.displayColorPicker, children: _jsx(CloseIcon, { size: "small" }) }), _jsx(SketchPicker, { className: `${themeClass}-color-picker`, disableAlpha: true, color: props.event.color ?? "#6264a7", presetColors: ["red", "orange", "gold", "green", "teal", "blue", "darkBlue", "indigo", "plum", "purple"], onChange: logic.onUpdateColor })] })), _jsx(Button, { icon: _jsx(UndoIcon, { outline: true }), fluid: isOnMobile, disabled: logic.state.readOnly || props.disabled, onClick: logic.onClearColor, content: t("Reset") })] })] }));
    };
    const tagsDropdownFilterLoadingMessage = () => {
        if (logic.state.tagIsLoading && logic.state.tagLoadingError) {
            return (_jsx(Segment, { styles: {
                    borderRadius: "5px",
                }, color: "red", inverted: true, children: _jsx(Text, { content: " " + t("Tag:UpdatingTagsError") }) }));
        }
        else if (logic.state.tagIsLoading) {
            return (_jsx(Segment, { styles: {
                    borderRadius: "5px",
                }, color: "brand", inverted: true, children: _jsx(Text, { content: " " + t("Tag:UpdatingTags") }) }));
        }
        else
            return null;
    };
    /**
     * Render tags input
     */
    const renderTagsInput = () => (_jsxs(Flex, { column: isOnMobile, gap: isOnMobile ? "gap.small" : null, styles: { width: "100%" }, children: [_jsx(Flex.Item, { styles: { minWidth: `${labelsMaxWidth} !important`, width: `${labelsMaxWidth} !important` }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(TagIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: t("TagsTitle") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsxs(Flex, { column: true, styles: { width: "calc(100% - 180px) !important" }, children: [_jsxs(Flex, { children: [_jsx(SearchDropdown, { FUI: {
                                        onSearchQueryChange: logic.handleSearchQueryChange,
                                        styles: { width: "100%", maxWidth: "356px" },
                                        items: (calendar?.tags ?? []).filter((tag) => props.event.tags?.includes(tag) === false),
                                        fluid: true,
                                        inverted: !isOnMobile,
                                        disabled: logic.state.readOnly || !isPremium || props.disabled,
                                        loading: logic.state.tagIsLoading,
                                        loadingMessage: tagsDropdownFilterLoadingMessage(),
                                        onChange: (_, data) => logic.tagsOnChange(_, data),
                                        onOpenChange: logic.openChangeTag,
                                        noResultsMessage: t("Tag:NoResult", { Search: logic.state.currentTag }),
                                        value: logic.state.currentTag,
                                    } }), _jsx(Button, { style: { margin: "0 10px", minWidth: "auto" }, primary: true, icon: _jsx(AddIcon, { outline: true }), disabled: !logic.state.currentTag || logic.state.currentTag === "" || props.disabled || logic.state.currentTag.length > 50, content: t("Add"), onClick: logic.onAddTag })] }), _jsx(Flex, { vAlign: "center", styles: { overflowX: "auto", whiteSpace: "nowrap" }, children: props.event.tags?.map((tag, i) => (_jsx(Alert, { style: {
                                    marginRight: "5px",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }, content: _jsxs(Flex, { styles: { width: "fit-content", padding: "0px 5px" }, vAlign: "center", hAlign: "center", gap: "gap.small", children: [_jsx(Text, { content: tag }), _jsx(CloseIcon, { size: "small", styles: { cursor: "pointer" }, onClick: logic.onRemoveTag(i) })] }) }, "tag_" + tag))) })] }) })] }));
    /**
     * Render address input
     */
    const renderAddressInput = () => (_jsxs(Flex, { column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(LocationIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: t("AddressTitle") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { vAlign: "center", children: _jsx(Input, { id: "inputRef", fluid: true, inverted: !isOnMobile, value: logic.state.address?.fullAddress ?? "", onChange: logic.onUpdateEventFromDropdownOrInput("address"), disabled: logic.state.readOnly || !isPremium || props.disabled, placeholder: t("AddAddress") }) }) })] }));
    /**
     * Render go premium message if user is freemium and using recurrence
     */
    const renderGoPremiumRecurrenceAlert = () => (_jsxs(Flex, { children: [_jsx(Flex, { styles: { width: isOnMobile ? 0 : labelsMaxWidth, marginLeft: "10px" } }), _jsx(Flex.Item, { children: _jsx(Alert, { warning: true, styles: { cursor: "pointer" }, content: _jsxs(Flex, { gap: "gap.small", children: [_jsx(Text, { style: { textDecoration: "underline" }, content: t("UnLockRecurrence") }), _jsx(Text, { children: " \uD83D\uDE80" })] }), onClick: logic.showPremiumDialog }) })] }));
    /**
     * Render go premium message if user is using advanced features
     */
    const renderGoPremiumAdvancedAlert = () => (_jsxs(Flex, { children: [_jsx(Flex, { styles: { width: isOnMobile ? 0 : labelsMaxWidth } }), _jsx(Flex.Item, { children: _jsx(Alert, { warning: true, content: _jsxs(Flex, { gap: "gap.small", children: [_jsx(Text, { style: { textDecoration: "underline", cursor: "pointer" }, content: t("AlertTagsAndMore"), onClick: logic.showPremiumDialog }), _jsx(Text, { content: "🚀" })] }) }) })] }));
    /**
     * Render advanced properties only for premium
     */
    const renderAdvancedProperties = () => (_jsx(Accordion, { defaultActiveIndex: [isPremium ? 0 : 1], className: "advanced-accordion", panels: [
            {
                title: t("Forward"),
                content: (_jsxs(Flex, { className: "accordion-container", fill: true, gap: "gap.medium", style: { marginBottom: "100px" }, column: true, children: [!isPremium && renderGoPremiumAdvancedAlert(), renderTagsInput(), allowAddFile && channelId && _jsx(FilesComponent, { event: props.event, updateEvent: props.onUpdateEvent, disabled: logic.state.readOnly || props.disabled }), _jsx(GoogleRenderComponent, { address: props.event.address, disabled: props.disabled, readOnly: logic.state.readOnly, updateAddress: props.updateAddress, checkPremium: true })] }, "premium_options")),
            },
        ] }));
    const renderParticpants = () => {
        return (_jsx(_Fragment, { children: _jsx(Participant, { members: props.event.participants ?? [], addMembers: logic.addParticipant, disabled: logic.state.readOnly || props.disabled }) }));
    };
    /**
     * Render hours dropdown
     * @param date
     * @param onChange
     */
    const renderHoursDropDown = (date, onChange) => {
        if (props.event.allDay)
            return;
        const hoursItems = getHours(logic.use12HoursFormat);
        highlightChildrenClass(props.hasClickOnSuggestedTime, ".hours-dropdown", ".ui-dropdown__container");
        return (_jsx(Dropdown, { fluid: true, styles: isOnMobile ? { width: "100%" } : {}, className: "hours-dropdown", inverted: !isOnMobile, disabled: logic.state.readOnly || props.disabled, value: formatToLocalHours(date, logic.use12HoursFormat), items: hoursItems, checkable: true, onChange: onChange }));
    };
    /**
     * Render minutes dropdown
     * @param date
     * @param onChange
     */
    const renderMinutesDropDown = (date, onChange) => {
        if (props.event.allDay)
            return null;
        const minutes = getMinutes();
        highlightChildrenClass(props.hasClickOnSuggestedTime, ".minutes-dropdown", ".ui-dropdown__container");
        return (_jsx(Dropdown, { fluid: true, styles: isOnMobile ? { width: "100%" } : {}, className: "minutes-dropdown", inverted: !isOnMobile, disabled: logic.state.readOnly || props.disabled, value: formatToLocalMinutes(date), items: minutes, checkable: true, onChange: onChange }));
    };
    /**
     * Render custom recurrence dialog
     */
    const renderCustomReccurenceDialog = () => {
        if (!logic.state.showCustomRecurrenceDialog)
            return null;
        return _jsx(CustomRecurrenceDialog, { show: logic.state.showCustomRecurrenceDialog, onClose: logic.onCloseCustomRecurrenceDialog, onConfirm: logic.onConfirmCustomRecurrenceDialog, event: props.event, onUpdateEventFromDropdownOrInput: logic.onUpdateEventFromDropdownOrInput });
    };
    const renderUntilDateInput = () => {
        return (_jsxs(Flex, { vAlign: "center", column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(CalendarIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: t("EndOfRecurrence") + " " + t("DateEndsRecurrence").toLowerCase() })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { column: isOnMobile, vAlign: "center", gap: "gap.small", children: _jsx(Datepicker, { allowManualInput: false, inputOnly: true, styles: { width: "100%" }, input: {
                                fluid: true,
                                inverted: !isOnMobile
                            }, formatMonthDayYear: logic.formatDateToLocalDateFormat, selectedDate: moment(props.event.rrule.until ?? props.event.endDate).toDate(), onDateChange: logic.onUpdateEventFromDropdownOrInput("rrule"), maxDate: props.event.modeRecurrence !== IOptionRecurrence.Yearly ? addYearAndHalf(props.event.startDate).toDate() : addFiveYears(props.event.startDate).toDate(), minDate: moment(props.event.startDate).toDate() }) }) })] }));
    };
    /**
     * Render component
     */
    return (_jsxs(Flex, { column: true, gap: "gap.medium", padding: "padding.medium", styles: { maxWidth: "100% !important" }, children: [!!props.event.id && renderAvatar(), calendar?.isResourceMode && renderResourceInput(), renderEmojiPicker(), renderTitleInput(), renderParticpants(), renderStartDateInput(), renderEndDateInput(), renderTimeZoneDropdown(), !datesAreValid(props.event) && renderDatesWarnMessage(), renderAllDayToggle(), renderRecurrenceDropdown(), !allowDoubleBooking && props.event.modeRecurrence !== IOptionRecurrence.NotRepeat && props.event.rrule.count === undefined && renderUntilDateInput(), !props.event.isRecurrenceValid && isPremium && renderRecurenceWarnMessage(), !isPremium && logic.state.showGoPremiumRecurrenceAlert && renderGoPremiumRecurrenceAlert(), renderDescriptionInput(), renderColorInput(), renderAdvancedProperties(), renderCustomReccurenceDialog()] }));
};
